import { Button, Popover } from "@mui/material";
import React from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useDispatch } from "react-redux";
import { errorAvailable } from "../../redux/errorSlice";


const DateComponent = (props) => {
  const { isOpen, onClose, setSelectedRange, selectedRange, onSubmit } = props;
const dispatch=useDispatch()
  const open = Boolean(isOpen);
  const id = isOpen ? "simple-popover" : undefined;
  const today = new Date();
  const maxDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );
  const onChangeHandler = (ranges) => {
    const { selection } = ranges;
    const { startDate, endDate } = selection;
    setSelectedRange((prevState) => ({
      ...prevState,
      startDate: startDate || prevState.startDate,
      endDate: endDate || prevState.endDate,
    }));
  };

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div style={{ margin: "1rem" }}>
          <DateRangePicker
            ranges={[selectedRange]}
            onChange={onChangeHandler}
            maxDate={maxDate}
            moveRangeOnFirstSelection={false}
            retainEndDateOnFirstSelection={true}
          />
          <div style={{ marginTop: "1rem", textAlign: "right" }}>
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: "0.5rem" }}
              onClick={()=>{
                if(selectedRange.startDate && selectedRange.endDate){
                  onSubmit()
                }
                else{
                  dispatch(errorAvailable("Select the date range..!"));
                }
              }}
            >
              Submit
            </Button>
            <Button variant="contained" color="error" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default DateComponent;
