import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/reff_logo.png";
import useMedia from "../../utils/useMedia";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AdUnitsIcon from "@mui/icons-material/AdUnits";
import PeopleIcon from "@mui/icons-material/People";
import ReviewIcon from "@mui/icons-material/Reviews";
import PersonIcon from "@mui/icons-material/Person";
import InterestIcon from "@mui/icons-material/Interests";
import {  ArrowRightSharp } from "@mui/icons-material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
const navigationData = [
  {
    id: 0,
    icon: <DashboardIcon />,
    name: "Dashboard",
    pathname: "dashboard",
  },
  {
    id: 1,
    icon: <PeopleIcon />,
    name: "User Management",
    pathname: "user-management",
  },
  {
    id: 2,
    icon: <AdUnitsIcon />,
    name: "Content Management",
    icon2:true,
    pathname: null,
    subItems: [
      { id: 3, icon: <PersonIcon />, name: "User Type", pathname: "content-management/user" },
      { id: 4, icon: <InterestIcon />, name: "Interest", pathname: "content-management/interest" },
      { id: 5, icon: <AccountCircleIcon />, name: "Industry", pathname: "content-management/industry" },
      { id: 6, icon: <AdUnitsIcon />, name: "Experience", pathname: "content-management/experience" },
      { id: 7, icon: <AccountCircleIcon />, name: "Goals", pathname: "content-management/goal" },
    ],
  },
  {
    id: 8,
    icon: <ReviewIcon />,
    name: "Reviews",
    pathname: "reviews",
  },
  {
    id: 9,
    icon: <ReviewIcon />,
    name: "Endorsement",
    pathname: "endorsement",
  },
  ,
  {
    id: 10,
    icon: <ReviewIcon />,
    name: "Expert Verification",
    pathname: "verification",
  },
  {
    id: 11,
    icon: <AccountCircleIcon />,
    name: "My Account",
    pathname: "account",
  },
];

const Navigation = () => {
  const { pathname } = useLocation();
  const [activeParent, setActiveParent] = useState(null); 
  const [activeItem, setActiveItem] = useState(null); 
  const [expanded, setExpanded] = useState(null); 
  const navigate = useNavigate();
  const { isSmallScreen } = useMedia(1200);
  useEffect(() => {
    const mainPath = pathname.split("/")[1];
    const subPath = pathname.split("/")[2];

    const getCurrentPathDetails = () => {
      for (const item of navigationData) {
        if (item?.subItems) {
          const subItem = item?.subItems.find(
            (sub) => sub?.pathname === `${mainPath}/${subPath}`
          );
          if (subItem) {
            setActiveParent(item?.id);
            setActiveItem(subItem?.pathname);
            setExpanded(item.id);
            return;
          }
        }

        if (item?.pathname === mainPath) {
          setActiveParent(item?.id);
          setActiveItem(item?.pathname);
          return;
        }
      }
    };

    getCurrentPathDetails();
  }, [pathname]);

  const navigateHandler = (value, parentId = null) => {
    setActiveItem(value.pathname);
    if (parentId) {
      setActiveParent(parentId);
    } else {
      setActiveParent(value.id);
    }
    navigate(`/${value.pathname}`);
  };

  const handleExpand = (item) => {
    if (item.subItems) {
      setExpanded(expanded === item.id ? null : item.id); 
    } else {
      navigateHandler(item);
      setExpanded(null); 
    }
  };

  return (
    <div className={`side-nav-main-wrapper ${isSmallScreen ? "mobileSideView" : "sideDesktopView"}`}>
      <div className="nav-main">
        {isSmallScreen ? null : (
          <div className="main-wrapper-workspace">
            <div className="inner_container">
              <div>
                <img src={Logo} alt="Logo"/>
              </div>
            </div>
          </div>
        )}
        <div className="side-pages-content">
          {navigationData.map((item) => (
            <React.Fragment key={item.id}>
              <div
                onClick={() => handleExpand(item)}
                className={`${
                  activeParent === item.id || activeItem === item.pathname ? "activePage" : ""
                }`}
              >
                {item.icon}
                <span>{item.name}</span>

                               {item?.icon2 ?<KeyboardArrowDownIcon />:""}   

              </div>
              {item.subItems && (
                <ul className={`side-subitems ${expanded === item.id ? "expand" : "collapse"}`}>
                  {item.subItems.map((subItem) => (
                    <div
                      key={subItem.id}
                      onClick={() => navigateHandler(subItem, item.id)}
                      className={`${activeItem === subItem.pathname ? "activeSubPage" : ""}`}
                    >
                      <ArrowRightSharp />
                      {subItem.icon}
                      <span className="a-subitems">{subItem.name}</span>
                    </div>
                  ))}
                </ul>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Navigation;
