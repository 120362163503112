import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";


export default function Modal(props) {
  const { isOpen, close, children, submitHandler, title, isDisable } = props;

  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        onClose={close}
        fullWidth
        maxWidth="sm"
        disableBackdropClick={true}
      >
        <DialogTitle className="dialog_title">{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => close()}
            sx={{
              background: "#5a2ead",
              color: "#fff",
              "&:hover": {
                background: "#804bdf",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={submitHandler}
            disabled={isDisable}
            sx={{
              background: isDisable? "grey": "#5a2ead",
              cursor: isDisable? "not-allowed": "pointer",
              color: "#fff !important",
              "&:hover": {
                background: isDisable? "grey": "#804bdf",
              },
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
