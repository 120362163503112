import React, { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import ProtectedRoute from "./privateRoute";
import NotFound from "../pages/404";
import CustomLoader from "../components/loader/CustomLoader";
import ErrorBoundary from "../pages/error-boundary";
import ResetPassword from "../pages/reset-password";
import ForgotPassword from "../pages/forgot-password";
import Login from "../pages/login";
import Layout from "../module/Layout";
import Account from "../components/my-profile";
import { MyProfile } from "../components";
import ReviewManagement from "../pages/review-management.js";
import ExpertVerification from "../pages/expert-verification.js";
const Dashboard = lazy(() => import("../pages/dashboard"));
const User = lazy(() => import("../pages/user-management"));
const ContentUser = lazy(() => import("../pages/usertype-management"));
const Interest = lazy(() => import("../pages/interest-management"));
const Industry = lazy(() => import("../pages/industry-management"));
const Experience = lazy(() => import("../pages/experience-management"));
const Goal = lazy(() => import("../pages/goal-management"));
const Endorsement = lazy(() => import("../pages/endorse-management.js"));

export const appRoutes = [
  {
    path: "/",
    element: (
      <Suspense fallback={<CustomLoader />}>
        <ErrorBoundary>
          <Layout />
        </ErrorBoundary>
      </Suspense>
    ),
    children: [
      {
        element: <ProtectedRoute />,
        children: [
          { path: "/dashboard", element: <Dashboard /> },
          { path: "/user-management", element: <User /> },
          { path: "content-management/user", element: <ContentUser /> },
          { path: "content-management/industry", element: <Industry /> },
          { path: "content-management/interest", element: <Interest /> },
          { path: "content-management/experience", element: <Experience /> },
          { path: "content-management/goal", element: <Goal /> },
          { path: "/endorsement", element: <Endorsement /> },
          { path: "/reviews", element: <ReviewManagement /> },
          { path: "/verification", element: <ExpertVerification /> },

          { path: "/account", element: <Account /> },
          { path: "/account/my-profile", element: <MyProfile /> },
          { path: "/reset-password-authorized", element: <ResetPassword /> },
        ],
      },
    ],
  },
  {
    path: "/forgot-password",
    element: (
      <ErrorBoundary>
        <ForgotPassword />
      </ErrorBoundary>
    ),
  },
  {
    path: "/login",
    element: (
      <ErrorBoundary>
        <Login />
      </ErrorBoundary>
    ),
  },
  {
    path: "/reset-password",
    element: (
      <ErrorBoundary>
        <ResetPassword />
      </ErrorBoundary>
    ),
  },
  {
    path: "/",
    element: <Navigate to="/login" replace />,
  },
  { path: "*", element: <NotFound /> },
];
