import React from "react";
import ResetTvIcon from "@mui/icons-material/ResetTv";
import logo from "../../assets/reff_logo.png";
const Index = () => {
  const emailAddress = localStorage.getItem("email");

  return (
    <div className="my-profile-wrapper">
      <div>
        <img src={logo} alt="logo" />
      </div>
      <div>
        <span>App Name</span>
        <h3>{"Reffyy App"}</h3>
      </div>
      <div>
        <span>Email</span>
        <h3>{emailAddress}</h3>
      </div>
      <a href="/reset-password">
        <span>Reset Password</span> <ResetTvIcon />
      </a>
    </div>
  );
};

export default Index;
