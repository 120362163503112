import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Input from "../input";
import { mutationAPI } from "../../api/services";
import { ADMIN_CONTENT_CREATE_EXPERIENCE, ADMIN_CONTENT_CREATE_INDUSTRY, ADMIN_CONTENT_EDIT_EXPERIENCE, ADMIN_CONTENT_EDIT_INDUSTRY, ADMIN_CONTENT_GET_EXPERIENCE, ADMIN_CONTENT_GET_INDUSTRY } from "../../api/constants";

import CrossIcon from "@mui/icons-material/Clear";
import Select from "../select/Select";
import { Options } from "../../utils/constants";

export default function ModalView(props) {
  const { isOpen, isEdit, close, data, title, isDisable, setData } = props;

  console.log({ data }, data?.media)
  const filter = Options?.filter((item) => item?.value === data?.description)
  const [selected, setSelected] = React.useState(filter?.[0]?.key || "");


  const [titleVal, setTitleVal] = React.useState(data?.title || "")



  const handleValueChange = (event) => {
    setSelected(event.target.value)
  };

  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        onClose={close}
        fullWidth
        maxWidth="sm"
        disableBackdropClick={true}
      >
        <DialogTitle className="dialog_title">Review

          <CrossIcon onClick={() => close(!isOpen)}
          />
        </DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", flexDirection: "column", gap: "20px", paddingTop: "1rem" }}>

            <div style={{display:"flex",justifyContent:"space-between"}}>
              <div>
                <p className="header-css">
                  Name
                </p>
                <p className="header-value-css">{data?.name ||"--"}</p>
                </div>
                
              <div>
                <p className="header-css">Date</p>
                <p className="header-value-css">{data?.date ||"--"}</p>
                </div>
              <div>
                <p className="header-css">Business Reviewed</p>

                <p className="header-value-css">{data?.reviewd ||"--"}</p>
                </div>
                </div>
            <p className="header-css">Media</p>
            <div style={{    flexWrap: "wrap",
    display: "flex",
    gap: "30px"}}>

              {data?.media?.map((item, index) => (
                <img src={item?.props.src} style={{ width: "100px", height: "100px" }} />
              ))}


            </div>
          
          </div>
        </DialogContent>

      </Dialog>
    </React.Fragment>
  );
}
