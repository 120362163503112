import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { getIcon } from "../../utils/helper";
import { useLocation } from "react-router-dom";
const Input = ({
  type,
  onChange,
  label,
  value,
  accept,
  mediaAttribute,
  name,
  placeholder = "",
  readOnly,
  customClass
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const { pathname } = useLocation();

  const getValue = () => {
    if (pathname?.includes("user-management")) {
      return value ? value : "";
    }
    else if (pathname?.includes("login")) {
      return value ? value : null;
    }
    else {
      return value
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  const noIcon = () => {
    switch (label) {
      case "Name":
      case "State":
        return false;
      default:
        return true;
    }
  };
  return (
    <div className={`input_container ${customClass}`}>
      <TextField
      // onSubmit={}
      InputLabelProps={{
        style: { color: "black" } // Change "red" to your desired color
      }}
        fullWidth
        id={name}
        type={showPassword ? "text" : type}
        sx={{ input: { cursor: "pointer",color:"black",background:"transparent !important" } }}
        onChange={onChange}
        value={getValue()}
        name={name}
        required={showPassword ? true : true}
        autoComplete="off"
        label={label}
        placeholder={placeholder}
        inputProps={{}}
        InputProps={{
          inputProps: { accept: accept, readOnly: readOnly },
          endAdornment: (
            <InputAdornment position="end">
              {noIcon() &&
                getIcon(
                  label ?? mediaAttribute,
                  showPassword,
                  type === "file" ? onChange : togglePasswordVisibility
                )}
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default Input;
