import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles

const Index = (props) => {
  const { value, name, onChange, className, label } = props;
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"], // blocks
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ align: [] }],
    ["clean"] // remove formatting button
  ];
  return (
    <div className={`lui-input-wrapper editor_component`}  onFocus={handleFocus}
    onBlur={handleBlur}>
      {label && (
        <label
          className={`lui-input-label ${isFocused ? "active" : ""} ${
            value ? "value-active" : ""
          } `}
        >
          {label}
        </label>
      )}

      <div className={`lui-input-container ${isFocused ? "focused" : ""} `}>
        <div
          className={`lui-input text-area-custom ${className}`}
         
        >
          <ReactQuill
            modules={{ toolbar: toolbarOptions }}
            theme="snow"
            value={value}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Index;
