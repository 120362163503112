import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux"; // Import Provider
import { store } from "./redux/store"; // Import your Redux store
import "./styles/styles.scss";
import ErrorBoundary from "./pages/error-boundary";
import App from "./App";


const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
  <App/>
  </Provider>
);
