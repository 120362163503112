import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Input from "../input";
import { Options } from "../../utils/constants";
import Select from "../select/Select";
import { getLabel } from "../../utils/helper";
import CrossIcon from "@mui/icons-material/Clear";
import { isDataLoading } from "../../redux/dataSlice";
import { useDispatch, useSelector } from "react-redux";
import { mutationAPI } from "../../api/services";
import { Notification, TextArea } from "..";
import logoProfile from "../../assets/profile.png"
import { Edit } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { errorAvailable, errorClose, errorStatus } from "../../redux/errorSlice";
import { useState } from "react";


export default function CustomModal(props) {
    const fileInputRef = React.useRef(null);

    const [selectedImage, setSelectedImage] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false)

    const dispatch = useDispatch()
    const { Open, Close, isEdit = false, dataResponse, setResponseData, title, isDisable, ApiData, defaultResponse, verify } = props;
    //Dynamic Modal Ui handling
    // console.log({successNoti})
    const getComponents = (key) => {
        // Ensure key exists in data
        if (!dataResponse.hasOwnProperty(key)) {
            return null;
        }
        if (key === "title" || key === "username" || key === "heading" || key === "value") {
            return (
                <div className="address-edit-wrapper">
                    <Input
                        type="text"
                        label={getLabel(key)} // Dynamically generate label
                        name={key} // Use the key as the name
                        value={dataResponse[key]} // Access the value of the key (e.g., data.title or data.years)
                        onChange={(e) =>
                            setResponseData((prevState) => ({
                                ...prevState,
                                [key]: e.target.value, // Update the specific key
                            }))
                        }
                    />
                </div>
            )
        } else if (key === "years") {
            return (
                <Select
                    label="Years"
                    value={dataResponse[key]}
                    name={key}
                    onChange={(e) => {
                        setResponseData({ ...dataResponse, [e.target.name]: e.target.value })

                    }

                    }
                    data={Options}
                />)
        } else if (key === "description") {
            return (

                <TextArea
                    type="text"
                    label="Description"
                    name="name"
                    value={dataResponse[key]}
                    onChange={(e) =>
                        setResponseData((prevState) => ({
                            ...prevState,
                            [key]: e.target.value, // Update the specific key
                        }))
                    } />)
        } else if (key === "url") {
            return (
                <div className="image-container">
                    {!selectedImage ?
                        <div >
                            <img src={logoProfile}
                                className="image-logo-css"
                            />
                        </div>
                        :
                        <img
                            src={selectedImage?.s3Url}
                            alt={`profile`}
                            style={{
                                objectFit: 'cover', margin: '3px', width: "100%",
                                height: " 100%",
                                borderRadius: "50%",
                            }}
                        />
                    }

                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                    />
                    <div onClick={handleButtonClick}
                        className="image-position"
                        style={{

                        }}>
                        <Edit style={{ height: ".833vw", width: ".833vw", color: "#86868B" }} />
                    </div>

                </div>
            )
        }
    };
    console.log({ dataResponse })

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };    //Submit Handler is here

    const handleImageChange = async (e) => {

        const file = e.target.files[0];
        if (file) {

            const reader = new FileReader();
            reader.onloadend = () => {
                // setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);

            const formData = new FormData();
            formData.append('files', file);
           
            try {
                const response = await mutationAPI(
                    "/v1/auth/uploadImage",
                    "POST",
                    formData
                )
                setSelectedImage(response?.data?.data?.[0])
                setResponseData({ ...dataResponse, ["url"]: response?.data?.data?.[0]?.s3Url })

            }
            catch (err) {

            }
        }
    };
    const validateForm = () => {
        const requiredFields = Object.keys(defaultResponse); // Fields that should not be empty
        for (let field of requiredFields) {
            console.log(dataResponse[field] ,"pookie")
            debugger
            if (!dataResponse[field] || dataResponse[field].trim() === "") {
                debugger
                return false; // Validation failed
            }
        }
        return true; // Validation passed
    };
    const submitHandler = async () => {
        if (!validateForm()) {
            console.log("Validation failed");
            setError("Please fill out all required fields."); // Replace with a better UI message if needed
            return;
        }
    
        try {
            const payload = {};
    
            // Build the payload dynamically
            if (dataResponse?._id) payload._id = dataResponse._id;
            if (dataResponse?.username) payload.userName = dataResponse.username;
            if (dataResponse?.title) payload.title = dataResponse.title;
            if (dataResponse?.heading) payload.heading = dataResponse.heading;
            if (dataResponse?.description) payload.description = dataResponse.description;
            if (dataResponse?.value) payload.value = dataResponse.value;
            if (dataResponse?.url || selectedImage?.s3Url) payload.url = selectedImage?.s3Url;
    
            // Start loading state
            dispatch(isDataLoading(true));
    
            // Perform the API call
            const response = await mutationAPI(ApiData?.endPoint, ApiData?.method, payload);
    
            dispatch(errorAvailable("Success"));
            dispatch(errorStatus("success"))
            // Update state on success
            setResponseData(defaultResponse);
            ApiData?.getApi();
            Close(!Open);

        } catch (error) {
            console.error("API error:", error);
            setError("An error occurred while submitting the form. Please try again.");
            dispatch(errorAvailable("An error occurred while submitting the form. Please try again."));
            dispatch(errorStatus("failure"))
        } finally {
            // Always end the loading state
            dispatch(isDataLoading(false));
        }
    };
    

    const handleClose = () => {
        Close(!Open)
        setResponseData(defaultResponse)
        setError("")
    }
    return (
        <React.Fragment>
            <Dialog
                className="dialog-content-box"
                open={Open}
                onClose={Close}
                fullWidth
                maxWidth="sm"
                disableBackdropClick={true}
            >
                {!verify &&
                    <DialogTitle className="dialog_title">{!isEdit ? `Create ${title}` : `Edit ${title}`}

                        {/* <CrossIcon onClick={() => Close()}
                        /> */}
                    </DialogTitle>}
                <DialogContent>

                    <div className="div_modal" >
                        {Object.keys(dataResponse)
                            ?.map((key) => (
                                <React.Fragment key={key}>{getComponents(key)}</React.Fragment>
                            ))
                        }
                        {error && (
                            <p className="err" >{error}</p>
                        )}
                    </div>
                </DialogContent>
                {!verify &&
                    <DialogActions>
                        <Button
                            onClick={submitHandler}
                            sx={{
                                textTransform: "capitalize",
                                fontWeight: 500,

                                background: isDisable ? "grey" : "#c9ff00",
                                cursor: isDisable ? "not-allowed" : "pointer",
                                color: "#fff !important",
                                "&:hover": {
                                    background: isDisable ? "grey" : "#c9ff00",
                                },
                            }}
                        >

                            <DoneIcon />
                        </Button>
                        <Button
                            onClick={() => handleClose()}
                            sx={{
                                fontWeight: 500,

                                textTransform: "capitalize",

                                background: "rgb(33, 43, 68)",
                                color: "#fff",
                                "&:hover": {
                                    background: "rgb(33, 43, 68)",
                                },
                            }}
                        >
                            <CloseIcon />
                        </Button>

                    </DialogActions>}

            </Dialog>
           
        </React.Fragment>
    );
}
