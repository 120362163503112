import React from "react";
import logo from "../../assets/main-icon.png";

const CustomLoader = () => {
  return (
    <div className="fallback-lazy-loader">
      <div>
        <img src={logo} alt="" />
      </div>
    </div>
  );
};

export default CustomLoader;
