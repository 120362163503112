import React from "react";
import Lottie from "lottie-react";
import animationData from "../loader/login-loader.json";
import Logo from "../../assets/main-icon.png";
import Logo1 from "../../assets/reffyy_full_navy.png";
const Loader = (props) => {
    const {animationStart}=props
  return (
    <div className="brand_loader">
    {animationStart ? <>  <div>
        <Lottie animationData={animationData} loop={true} />
      </div>
      <img src={Logo} alt="logo" id={animationStart? "initial_img":""}/></>:  <img src={Logo1} alt="logo" />}
    </div>
  );
};

export default Loader;
