import { TablePagination } from "@mui/material"
import { ADMIN_USER_LISTS } from "../../api/constants";
import { mutationAPI } from "../../api/services";



 const Index = ({rowsPerPage , page  ,setPage , setRowsPerPage ,url})=>{
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = async(event) => {
    console.log(event.target.value ,"---");
    
        setRowsPerPage(parseInt(event.target.value, 10));
        await mutationAPI(`${url}?skipVal=${page}&limitVal=${rowsPerPage}`)
        setPage(0);
      };


    return (
        <TablePagination
        component="div"
        count={100}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    )
}


export default Index;