import * as React from "react";
import {
  TextArea,
  Select,
  Input,
  ErrorField,
  Button as ChooseFile,
  Editor,
} from "..";
import {
  ethnicity,
  party,
  Options,
  stance,
  status,
} from "../../utils/constants";
import { UUIDGenerator, getLabel } from "../../utils/helper";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { errorAvailable } from "../../redux/errorSlice";
import { produce } from "immer"; // Corrected import

export default function Modal(props) {
  const { formData, setFormData, errors, setErrors, isCandidateMatchUp } = props;

  const [mediaFiles, setMediaFiles] = React.useState({ img: "", video: "" });
  const dispatch = useDispatch();


  const getValue = (key, loc, nestedKey) => {
    if (
      typeof formData[key] === "object" &&
      key !== "politicalExperience" &&
      !Array.isArray(formData[key])
    ) {
      return formData?.[key]?.[loc];
    } else if (
      typeof formData[key] === "object" &&
      key === "politicalExperience" &&
      !Array.isArray(formData[key])
    ) {
      return formData[key][loc][nestedKey];
    } else if (Array.isArray(formData[key])) {
      const item = formData?.stanceContent?.find(
        (item) => item?._id === nestedKey
      );

      return item ? item[loc] : null;
    } else {
      return formData?.[key];
    }
  };

  const handleFormChange = ({ key, InnerKey, innerId, value }) => {
    let htmlContent = "<p><br></p>";
    setErrors({});
    setFormData((prevFormData) =>
      produce(prevFormData, (draft) => {
        if (prevFormData?.address && key in prevFormData?.address) {
          draft.address[key] = value === htmlContent ? "" : value;
        } else if (key === "politicalExperience") {
          if (!draft[key]) draft[key] = {};
          if (!draft[key][InnerKey]) draft[key][InnerKey] = {};
          draft[key][InnerKey][innerId] = value === htmlContent ? "" : value;
        } else if (Array.isArray(prevFormData?.[InnerKey])) {
          const item = draft[InnerKey].find((item) => item?._id === innerId);
          if (item) {
            item[key] = value === htmlContent ? "" : value;
          }
        } else {
          draft[key] = value === htmlContent ? "" : value;
        }
      })
    );
  };

  const getComponents = (value) => {
    if (value === "address" && typeof formData[value] === "object") {
      return (
        <div className="address-edit-wrapper">
          {Object.keys(formData?.[value])
            ?.filter((item) => item !== "location")
            ?.map((location, index) => (
              <React.Fragment key={index}>
                <Input
                  type="text"
                  label={getLabel(location)}
                  name={getLabel(location)}
                  value={getValue(value, location)}
                
                  onChange={(e) =>
                    handleFormChange({
                      key: location,
                      value: e.target.value,
                    })
                  }
                />
                {errors?.hasOwnProperty(location) && (
                  <ErrorField error={errors[location]} />
                )}
              </React.Fragment>
            ))}
        </div>
      );
    } else if (value === "media") {
      return (
        <div className="input-media-img-container">
          <label htmlFor="imageInput" className="choose-file">
            Choose Image
          </label>
          <Input
            type="file"
            accept="image/*"
            mediaAttribute={getLabel(value)}
            name={getLabel(value)}
            onChange={(e) => {
              const file = e.target.files[0];
              const imageURL = file && URL?.createObjectURL(file);
              setMediaFiles({ ...mediaFiles, img: imageURL });
              handleFormChange({ key: value, value: file });
            }}
          />
          {(mediaFiles?.img || getValue(value)) && (
            <img
              src={mediaFiles?.img ? mediaFiles?.img : getValue(value)}
              alt="media"
            />
          )}
          {errors?.hasOwnProperty(value) && (
            <ErrorField error={errors[value]} />
          )}
        </div>
      );
    } else if (value === "video") {
      return (
        <div className="input-media-img-container">
          <label htmlFor="imageInput" className="choose-file">
            Choose Video
          </label>
          <Input
            type="file"
            accept="video/*"
            mediaAttribute={getLabel(value)}
            name={getLabel(value)}
            onChange={(e) => {
              const file = e.target.files[0];
              const videoUrl = file && URL?.createObjectURL(file);
              setMediaFiles({ ...mediaFiles, video: videoUrl });
              const video = document.createElement("video");
              video.src = URL.createObjectURL(file);

              video.onloadedmetadata = () => {
                const duration = video.duration;
                if (duration > 60) {
                  dispatch(
                    errorAvailable(
                      "Please select video having duration below 1 minute"
                    )
                  );
                }
              };
              handleFormChange({ key: value, value: e.target.files[0] });
            }}
          />
          {(mediaFiles?.video || getValue(value)) && (
            <video autoPlay muted key={mediaFiles.video}>
              <source src={mediaFiles.video} type="video/mp4" />
            </video>
          )}
          {errors?.hasOwnProperty(value) && (
            <ErrorField error={errors[value]} />
          )}
        </div>
      );
    } else if (value === "description") {
      return (
        <div className="text-area-wrapper-matchup">
          <Editor
            className="description"
            placeholder={getLabel(value)}
            label={getLabel(value)}
            name={getLabel(value)}
            value={getValue(value)}
            onChange={(e) => handleFormChange({ key: value, value: e })}
          />
          {errors?.hasOwnProperty(value) && (
            <ErrorField error={errors[value]} />
          )}
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <Input
            type={value === "password" ? "password" : "text"}
            label={getLabel(value)}
            name={getLabel(value)}
            value={getValue(value)}
            onChange={(e) =>
              handleFormChange({ key: value, value: e.target.value })
            }
          />
          {errors?.hasOwnProperty(value) && (
            <ErrorField error={errors[value]} />
          )}
        </React.Fragment>
      );
    }
  };

  return (
    <div>
      {Object.keys(formData)?.length
        ? Object.keys(formData)
            ?.filter(
              (item) =>
                item !== "_id" &&
                item !== "id" &&
                item !== "__v" &&
                item !== "joiningDate" &&
                item !== "updatedAt"
            )
            ?.map((key) => (
              <React.Fragment key={key}>{getComponents(key)}</React.Fragment>
            ))
        : null}
    </div>
  );
}
