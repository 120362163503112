import { configureStore } from '@reduxjs/toolkit';
import navigationSlice from './navigationSlice';
import dataSlice from './dataSlice';
import errorSlice from './errorSlice';


export const store = configureStore({
  reducer: {
    navigation: navigationSlice,
    data:dataSlice,
    error:errorSlice
  }
})