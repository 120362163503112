import * as React from "react";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

export default function PopUp(props) {
  const { isOpen, close, submitHandler, title, children } = props;
  return (
    <>
      <Dialog open={isOpen} className="dialog-content-box">
        {title ? (
          <DialogTitle
          >
            {title}
          </DialogTitle>
        ) : null}
        {!title ? <DialogContent>{children}</DialogContent> : null}
        <DialogActions >
          <Button
            onClick={submitHandler}
            className="submit-button"
          >
            <DoneIcon />
          </Button>
          <Button
            onClick={close}
            className="close-button"
          >
            <CloseIcon />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
