import React from "react";
import { Button } from "../";
import { useNavigate } from "react-router-dom";
const Index = ({ closeMenu }) => {
  const PROFILE_MENU_BUTTONS = ["My Profile","Logout", ];
  const navigate = useNavigate();
  const menuItemsClickHandler = (value) => {
    if (value === "Logout") {
      localStorage.clear();
      navigate("/login");
    } else {
      navigate("/account/my-profile");
    }
    closeMenu?.(null);
  };
  return (
    <div className="menu-wrapper">
      {PROFILE_MENU_BUTTONS?.map((item) => {
        return (
          <Button
            key={item}
            onClick={() => {
              menuItemsClickHandler(item);
            }}
          >
            {item}
          </Button>
        );
      })}
    </div>
  );
};

export default Index;
