import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Input, Notification } from "../components";
import Logo from "../assets/reffyy_full_navy.png";
import { mutationAPI } from "../api/services";
import {
  ADMIN_RESET_PASSWORD,
  ADMIN_RESET_PASSWORD_DIRECTLY,
} from "../api/constants";
import ErrorField from "../components/error-field";
import {
  passwordSchema,
  confirmPasswordSchema,
  resetPasswordDirectlySchema,
  ResetPasswordSchema,
} from "../utils/schema";
import { errorAvailable, errorClose, errorStatus } from "../redux/errorSlice";
import { useValidationErrors } from "../utils/useErrors";
import { useDispatch, useSelector } from "react-redux";
import { title } from "../utils/constants";
import axios from "axios";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dispatch = useDispatch();
  const [errors, setErrors, handleValidationErrors] = useValidationErrors();
  const errorDetails = useSelector((state) => state?.error);
  const token = queryParams.get("token");
  console.log({ formData })
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData?.password && formData?.confirmPassword) {
      try {
        const token = localStorage?.getItem("ACCESS_TOKEN")
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/v1/admin/resetPassword`,

          {
            "currentPassword": formData?.password,
            "newPassword": formData?.confirmPassword
          },
          {
            headers: {
              Authorization: `Bearer ${token}`, // Add "Bearer " prefix
            }
          }
        );

        if (response.status === 200) {
          dispatch(errorAvailable("Password reset successfully! Please login."));
          dispatch(errorStatus("success"))
          localStorage.clear();
          navigate("/login", { state: true });
        }
        console.log({ response }, "lklklk")
        if (response.status === 400) {
          dispatch(errorAvailable(response?.err?.response?.data?.message || "Something Went Wrong."));
        }
        if (response.status === 422) {
          alert(response)
          dispatch(errorAvailable(response?.err?.response?.data?.message));
        }
      } catch (error) {
        console.log({ error })
        handleValidationErrors(error?.message);
      }
    } else {
      setErrors({
        password: formData?.password ? "" : "Kindly enter password.",
        newPassword: formData?.confirmPassword ? "" : "Kindly enter Confirmpassword.",
      })
    }
  };

  const handleFormChange = ({ key, value }) => {
    setErrors({});
    setFormData({ ...formData, [key]: value });
  };

  return (
    <div
      className={`lui-auth-wrapper `}
    >
      <div className="lui-auth-container">
        <div className="lui-auth-banner">
          <img src={Logo} alt="logo" />
        </div>
        <div className="lui-auth-heading">
          <h5>Reset Password!</h5>
        </div>
        <div className="lui-auth-form">
          <form autoComplete="off">

            <Input
              placeholder="Current Password"
              type="password"
              label={"Password"}
              name={"password"}
              value={

                formData?.["password"]
              }
              onChange={(e) =>
                handleFormChange({
                  key: "password",
                  value: e.target.value,
                })
              }
            />
            <ErrorField error={errors?.password} />

            <Input
              placeholder="Confirm Password"
              type="password"
              label="Confirm Password"
              name="confirmPassword"
              value={formData?.confirmPassword}
              onChange={(e) =>
                handleFormChange({
                  key: "confirmPassword",
                  value: e.target.value,
                })
              }
            />
            <ErrorField error={errors?.newPassword} />

            <Button
              variant="primary"
              onClick={handleSubmit}
              className="login-btn"
            >
              {"Reset"}
            </Button>
          </form>
        </div>
      </div>
      <Notification
        value={errorDetails?.errorName}
        display={errorDetails?.errorDisplay}
        onClose={() => {
          dispatch(errorClose(false));
        }}
      />
    </div>
  );
};

export default ResetPassword;
