export const party = [
  { key: "Democratic Party", value: "Democratic Party" },
  { key: "Republican Party", value: "Republican Party" },
  { key: "Libertarian Party", value: "Libertarian Party" },
  { key: "Reform Party", value: "Reform Party" },
  { key: "Socialist Party", value: "Socialist Party" },
  { key: "Green Party", value: "Green Party" },
  { key: "Constitution Party", value: "Constitution Party" },
  { key: "Natural Law Party", value: "Natural Law Party" },
  { key: "Undecided", value: "Undecided" },
  { key: "Independent", value: "Independent" },
];

export const stance = [
  { key: "Education", value: "Education" },
  { key: "Voting Rights", value: "Voting Rights" },
  { key: "National Security", value: "National Security" },
  { key: "Criminal Justice", value: "Criminal Justice" },
  { key: "Economy", value: "Economy" },
  { key: "Environment", value: "Environment" },
  { key: "Health", value: "Health" },
];

export const UsState = [
  { id: "AL", key: "Alabama", value: "Alabama" },
  { id: "AR", key: "Arkansas", value: "Arkansas" },
  { id: "AZ", key: "Arizona", value: "Arizona" },
  { id: "CA", key: "California", value: "California" },
  { id: "CO", key: "Colorado", value: "Colorado" },
  { id: "CT", key: "Connecticut", value: "Connecticut" },
  { id: "DC", key: "District of Columbia", value: "District of Columbia" },
  { id: "DE", key: "Delaware", value: "Delaware" },
  { id: "FL", key: "Florida", value: "Florida" },
  { id: "GA", key: "Georgia", value: "Georgia" },
  { id: "IA", key: "Iowa", value: "Iowa" },
  { id: "ID", key: "Idaho", value: "Idaho" },
  { id: "IL", key: "Illinois", value: "Illinois" },
  { id: "IN", key: "Indiana", value: "Indiana" },
  { id: "KS", key: "Kansas", value: "Kansas" },
  { id: "KY", key: "Kentucky", value: "Kentucky" },
  { id: "LA", key: "Louisiana", value: "Louisiana" },
  { id: "MA", key: "Massachusetts", value: "Massachusetts" },
  { id: "MD", key: "Maryland", value: "Maryland" },
  { id: "ME", key: "Maine", value: "Maine" },
  { id: "MI", key: "Michigan", value: "Michigan" },
  { id: "MN", key: "Minnesota", value: "Minnesota" },
  { id: "MO", key: "Missouri", value: "Missouri" },
  { id: "MS", key: "Mississippi", value: "Mississippi" },
  { id: "MT", key: "Montana", value: "Montana" },
  { id: "NC", key: "North Carolina", value: "North Carolina" },
  { id: "ND", key: "North Dakota", value: "North Dakota" },
  { id: "NE", key: "Nebraska", value: "Nebraska" },
  { id: "NH", key: "New Hampshire", value: "New Hampshire" },
  { id: "NJ", key: "New Jersey", value: "New Jersey" },
  { id: "NM", key: "New Mexico", value: "New Mexico" },
  { id: "NV", key: "Nevada", value: "Nevada" },
  { id: "NY", key: "New York", value: "New York" },
  { id: "OH", key: "Ohio", value: "Ohio" },
  { id: "OK", key: "Oklahoma", value: "Oklahoma" },
  { id: "OR", key: "Oregon", value: "Oregon" },
  { id: "PA", key: "Pennsylvania", value: "Pennsylvania" },
  { id: "RI", key: "Rhode Island", value: "Rhode Island" },
  { id: "SC", key: "South Carolina", value: "South Carolina" },
  { id: "SD", key: "South Dakota", value: "South Dakota" },
  { id: "TN", key: "Tennessee", value: "Tennessee" },
  { id: "TX", key: "Texas", value: "Texas" },
  { id: "UT", key: "Utah", value: "Utah" },
  { id: "VA", key: "Virginia", value: "Virginia" },
  { id: "VT", key: "Vermont", value: "Vermont" },
  { id: "WA", key: "Washington", value: "Washington" },
  { id: "WI", key: "Wisconsin", value: "Wisconsin" },
  { id: "WV", key: "West Virginia", value: "West Virginia" },
  { id: "WY", key: "Wyoming", value: "Wyoming" },
];

export const status = [
  { key: "draft", value: "draft" },
  { key: "published", value: "publish" },
];

export const ethnicity = [
  { value: "Hispanic Origin", key: "Hispanic Origin" },
  { value: "Not of Hispanic Origin", key: "Not of Hispanic Origin" },
];
export const Options = [
  { value: "1-2 years", key: "1-2" },
  {
    value: "2-4 years",
    key: "2-4",
  },
  { value: "4-6 years", key: "4-6" },
  {
    value: "6-8 years",
    key: "6-8",
  },
  { value: "10+", key: "10+" },
];
export const title = {
  delete: "Are you sure you want to delete?",
  publish: "Do you want to publish this Advertisement?",
  wrong: "Something went wrong...!",
};
