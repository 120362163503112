import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  errorDisplay: false,
  errorName: "",
  errorStatus:"success"
};
const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    errorAvailable: (state, action) => {
      state.errorDisplay = true;
      state.errorName = action.payload || "";
    },
    errorClose: (state, action) => {
      state.errorDisplay = action.payload;
    },
    errorStatus: (state, action) => {
      state.errorStatus = action.payload;
    },
  },
});

export const { errorAvailable, errorClose,errorStatus } = errorSlice.actions;

export default errorSlice.reducer;
