import React from "react";
import Lottie from "lottie-react";
import animationData from "./animation.json";
import logo from "../../assets/main-icon.png";
const Loader = () => {
  return (
    <div className="fallback__loader">
      <Lottie animationData={animationData} loop={true} />
      <div className="loader_img">
        {/* <img src={logo} alt="logo" /> */}
      </div>
    </div>
  );
};

export default Loader;
