import React from "react";
import { Paper, Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
const ChartSkeleton = () => {
  return (
    <div className="chart_skeleton">
      <Skeleton variant="circular" width={300} height={300} />
      <div className="inner_chart_skeleton"></div>
    </div>
  );
};

const TableSkeleton = () => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#5a2ead",
      color: theme.palette.common.white,
      cursor: "pointer",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      cursor: "pointer",
    },
  }));

  const skeletonRowCount = 8;
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ width: "100%", border: "1px solid rgba(58, 53, 65, 0.12)" }}
        aria-label="customized table"
      >
        <React.Fragment>
          {Array.from(Array(skeletonRowCount).keys()).map((index) => (
            <TableRow key={index}>
              {[...Array(5)].map((_, cellIndex) => (
                <TableCell key={cellIndex}>
                  <Skeleton />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </React.Fragment>
      </Table>
    </TableContainer>
  );
};
const SkeletonCard = ({ value, width, height, borderRadius, margin , className}) => {
  return (
    <>
      {Array.from(Array(value).keys()).map((index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          width={width}
          height={height}
          style={{ borderRadius: borderRadius, margin:margin , maxWidth:"100%"}}
          className="card_skeleton"
        />
      ))}
    </>
  );
};

export default {
  Chart: ChartSkeleton,
  Table: TableSkeleton,
  Card: SkeletonCard,
};
