import React from "react";
import Lottie from "lottie-react";
import animationData from "./animation.json";

const Loader = () => {
  return (
    <div style={{ width: "150px" }}>
      <Lottie animationData={animationData} loop={true} />
    </div>
  );
};

export default Loader;
