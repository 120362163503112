import * as React from "react";
import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup";
import { styled } from "@mui/system";

export default function Index({ anchor, children, onClose, placement, style,items }) {
  console.log({items})
  const open = Boolean(anchor);
  const id = open ? "simple-popup" : undefined;
  const handleClose = (event) => {
    // Check if the click occurred inside the pop-up content
    if (anchor && anchor.contains(event.target)) {
      return;
    }

    onClose && onClose();
  };

  React.useEffect(() => {
    // Attach event listener to the document
    document.addEventListener("click", handleClose);

    // Detach event listener when component unmounts
    return () => {
      document.removeEventListener("click", handleClose);
    };
  }, [handleClose]);
  const handleCardClick = (event) => {
    // Prevent closing the card when clicking inside the card content
    event.stopPropagation();
  };
  return (
    <div >
      <BasePopup
      
      style={{top:"30px"}}
        id={id}
        open={open}
        anchor={anchor}
        placement={placement}
        onClose={onClose}
        onClick={handleCardClick}
      >
        < > {children}</>
      </BasePopup>
    </div>
  );
}


