import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { closeNavigation, openNavigation } from "../../redux/navigationSlice";
import FormatIndentIncreaseIcon from "@mui/icons-material/FormatIndentIncrease";
import FormatIndentDecreaseIcon from "@mui/icons-material/FormatIndentDecrease";
import useMedia from "../../utils/useMedia";
import logo from "../../assets/avatar.png";
import { FloatPopUp, Menu } from "..";

const Index = () => {
  const { isOpen } = useSelector((state) => state?.navigation);
  const dispatch = useDispatch();
  const { isSmallScreen } = useMedia(1200);
  const [menuClickedItem, setMenuClickedItem] = useState(null);
  const openSideNavHandler = () => {
    if (isSmallScreen && isOpen === null) {
      dispatch(openNavigation(false));
    } else {
      dispatch(openNavigation(true));
    }
  };

  const closeSideNavHandler = () => {
    dispatch(closeNavigation(false));
  };
  const menuHandler = (e) => {
    setMenuClickedItem(menuClickedItem ? null : e.target);
  };

  return (
    <nav>
      <div>
        <div
          className={isSmallScreen && !isOpen && isOpen !== null ? "blink" : ""}
        >
          {isOpen && isOpen !== null ? (
            <FormatIndentDecreaseIcon onClick={closeSideNavHandler} />
          ) : (
            <FormatIndentIncreaseIcon onClick={openSideNavHandler} />
          )}
        </div>
        <div className="logo_initial" onClick={menuHandler}>
          <img src={logo} alt="logo" />
        </div>
      </div>
      <FloatPopUp
        anchor={menuClickedItem}
        onClose={() => {
          setMenuClickedItem(null);
        }}
        children={<Menu closeMenu={setMenuClickedItem} />}
        style={{ zIndex: 1100 }}
        placement="top-end"
      />
    </nav>
  );
};

export default Index;
