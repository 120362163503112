import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Input, Notification } from "../components";
import Logo from "../assets/reffyy_full_navy.png";
import { mutationAPI } from "../api/services";
import {
  ADMIN_RESET_PASSWORD,
  ADMIN_RESET_PASSWORD_DIRECTLY,
} from "../api/constants";
import ErrorField from "../components/error-field";
import {
  passwordSchema,
  confirmPasswordSchema,
  resetPasswordDirectlySchema,
  ResetPasswordSchema,
} from "../utils/schema";
import { errorAvailable, errorClose } from "../redux/errorSlice";
import { useValidationErrors } from "../utils/useErrors";
import { useDispatch, useSelector } from "react-redux";
import { title } from "../utils/constants";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dispatch = useDispatch();
  const [errors, setErrors, handleValidationErrors] = useValidationErrors();
  const errorDetails = useSelector((state) => state?.error);
  const token = queryParams.get("token");
  const isDirectlyReset = queryParams.get("directlyreset");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // validate the fields through custom made schema Yup
      await ResetPasswordSchema.concat(confirmPasswordSchema).validate(
        formData,
        { abortEarly: false }
      );

      formData.token = token;

      const response = await mutationAPI(ADMIN_RESET_PASSWORD, "POST", {
        password: formData?.password.trim(),
        token,
      });
      if (response.status === 200) {
        dispatch(errorAvailable("Password reset successfully! Please login."));
        localStorage.clear();
        navigate("/login", { state: true });
      }
      if (response.status === 400) {
        dispatch(errorAvailable("Something Went Wrong."));
       
      }
    } catch (error) {
      handleValidationErrors(error);
    }
  };
  const handleDirectlySubmit = async (e) => {
    e.preventDefault();
    try {
      await resetPasswordDirectlySchema.validate(formData, {
        abortEarly: false,
      });

      const response = await mutationAPI(
        ADMIN_RESET_PASSWORD_DIRECTLY,
        "POST",
        {
          oldPassword: formData?.oldPassword,
          newPassword: formData?.newPassword,
        }
      );

      if (response.status === 200) {
        dispatch(errorAvailable("Password reset successfully! Please login."));
        localStorage.clear();
        navigate("/login", { state: true });
      } else if (response.status === 400) {
        dispatch(errorAvailable(response?.err?.response?.data?.message));
      } else if (response.status === 500) {
        dispatch(errorAvailable(title?.wrong));
      }
    } catch (error) {
      handleValidationErrors(error);
    }
  };
  const handleFormChange = ({ key, value }) => {
    setErrors({});
    setFormData({ ...formData, [key]: value });
  };

  return (
    <div
      className={`lui-auth-wrapper ${
        isDirectlyReset ? "reset-authorized-page" : ""
      }`}
    >
      <div className="lui-auth-container">
        <div className="lui-auth-banner">
          <img src={Logo} alt="logo" />
        </div>
        <div className="lui-auth-heading">
          <h5>Reset Password!</h5>
        </div>
        <div className="lui-auth-form">
          <form autoComplete="off">
            {isDirectlyReset && (
              <>
                <Input
                  type="password"
                  label="Old Password"
                  name="oldPassword"
                  value={formData?.["oldPassword"]}
                  onChange={(e) =>
                    handleFormChange({
                      key: "oldPassword",
                      value: e.target.value,
                    })
                  }
                />
                <ErrorField error={errors?.oldPassword} />
              </>
            )}

            <Input
              type="password"
              label={isDirectlyReset ? "New Password" : "Password"}
              name={isDirectlyReset ? "newPassword" : "password"}
              value={
                isDirectlyReset
                  ? formData?.["newPassword"]
                  : formData?.["password"]
              }
              onChange={(e) =>
                handleFormChange({
                  key: isDirectlyReset ? "newPassword" : "password",
                  value: e.target.value,
                })
              }
            />
            <ErrorField error={errors?.newPassword ?? errors?.password} />

            <Input
              type="password"
              label="Confirm Password"
              name="confirmPassword"
              value={formData?.confirmPassword}
              onChange={(e) =>
                handleFormChange({
                  key: "confirmPassword",
                  value: e.target.value,
                })
              }
            />
            <ErrorField error={errors?.confirmPassword} />

            <Button
              variant="primary"
              onClick={isDirectlyReset ? handleDirectlySubmit : handleSubmit}
              className="login-btn"
            >
              {isDirectlyReset ? "Reset" : "Create"}
            </Button>
          </form>
        </div>
      </div>
      <Notification
        value={errorDetails?.errorName}
        display={errorDetails?.errorDisplay}
        onClose={() => {
          dispatch(errorClose(false));
        }}
      />
    </div>
  );
};

export default ResetPassword;
