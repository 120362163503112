import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {Input,TextArea} from "../index"
import { mutationAPI } from "../../api/services";

import { ADMIN_CONTENT_CREATE_USERTYPE ,ADMIN_CONTENT_EDIT_USERTYPE, ADMIN_CONTENT_GET_USERTYPE} from "../../api/constants";
import CrossIcon from "@mui/icons-material/Clear";
import { useDispatch } from "react-redux";
import { isDataLoading } from "../../redux/dataSlice";


export default function UserTypeModal(props) {
  const { isOpen,isEdit, close, data, title, isDisable ,setData } = props;

  const [heading , setHeading] = React.useState(data?.heading || "");
  const [description , setDescription] = React.useState(data?.description || "");
  const dispatch = useDispatch()
  const submitHandler=async()=>{
    const payload={
      heading: heading,
      description:description
    }
    if (data?._id) {
      payload._id = data._id;
    }
    if(isEdit){
      try {
        dispatch(isDataLoading(true))
      const response = await mutationAPI(ADMIN_CONTENT_EDIT_USERTYPE, "PATCH",payload);

      if(response?.status==200){
      const data =await mutationAPI(ADMIN_CONTENT_GET_USERTYPE, "GET",{});
      setData(data?.data?.data)
    }
      close()
    } catch (error) {
      close()
    }finally{
      dispatch(isDataLoading(false))
    }
    }else{
      try {
        const response = await mutationAPI(ADMIN_CONTENT_CREATE_USERTYPE, "POST",payload);
        console.log({response})
        if(response?.status==200){
          const data =await mutationAPI(ADMIN_CONTENT_GET_USERTYPE, "GET",{});
          setData(data?.data?.data)      
        }      
        close()
      } catch (error) {
        close()
      }
    }

  }

  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        onClose={close}
        fullWidth
        maxWidth="sm"
        disableBackdropClick={true}
      >
        <DialogTitle className="dialog_title">{isEdit ? "Edit User Type ":"Create User Type"}
        <CrossIcon onClick={() => close()}/>

        </DialogTitle>

        <DialogContent>

      {/* <p className="input-label" >User Type</p>
        <Input
              type="text"
              label=""
              name="name"
              value={data?.userType}
              customClass="custom-class"
              // onChange={fetchDataByName}
            /> */}
            {/* <p className="input-label">Heading</p> */}

              <Input
              type="text"
              label="Heading"
              name="name"
              value={heading}
              onChange={(e) => setHeading(e?.target?.value)}
            />
            {/* <p className="input-label">Description</p> */}

            <TextArea
            type="text"
            label="Description"
            name="name"
            value={description}
            onChange={(e) => setDescription(e?.target?.value)}
            />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => close()}
            sx={{
              textTransform:"capitalize",

              background: "Black",
              color: "#fff",
              "&:hover": {
                background: "Black",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={submitHandler}
            disabled={isDisable}
            sx={{
              textTransform:"capitalize", fontWeight:600 ,
              background: isDisable? "grey": "#c9ff00",
              cursor: isDisable? "not-allowed": "pointer",
              color: "black !important",
              "&:hover": {
                background: isDisable? "grey": "#c9ff00",
              },
            }}
          >
            {isEdit?"Update":"Create"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
