import React, { useEffect } from "react";
import {
  Loader,
  Nav,
  Navigation,
  Notification,
  Skeleton,
} from "../../components/index";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useMedia from "../../utils/useMedia";
import { defaultNavigation } from "../../redux/navigationSlice";
import { Button } from "@mui/material";
import { errorClose } from "../../redux/errorSlice";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";

const Index = () => {

  const skeletonLoading = useSelector((state) => state?.data?.skeletonLoading);
  const { isOpen } = useSelector((state) => state?.navigation);
  const { isSmallScreen } = useMedia(1200);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { isLoading } = useSelector((state) => state.data);
  const errorDetails = useSelector((state) => state?.error);
  const navigate = useNavigate();
  useEffect(() => {
    if (isSmallScreen) dispatch(defaultNavigation(null));
  }, [isSmallScreen]);

  const getNestedPath = (subPath) => {
    return (
      <div className="nested__path">
        <span>Content Management</span> <DoubleArrowIcon /> {subPath}
      </div>
    );
  };

  const getPathName = {
    "/user-management": "User Management",
    "/dashboard": "DashBoard",
    "/account": "My Profile",
    "/reviews":"Review Management",
    "/endorsement":"Endorsement Management",
    "/verification":"Expert Verification Management",


    "/content-management/industry": getNestedPath(
      "Industry Management"
    ),
    "/content-management/user": getNestedPath(
      "User Type Management"
    ),
    "/content-management/goal":  getNestedPath(
      "Goal Management"
    ),
    "/content-management/experience": getNestedPath(
      "Experience Management"
    ),

    "/content-management/interest": getNestedPath(
     "Interest Management"
    ),
    "/content-management/endorsement":  getNestedPath(
     "Endorsement Management"
     ),
  };
  const INCLUDE_BACK_BUTTON = [
    "/info-management/ready-to-vote",
    "/info-management/vote-forms",
    "/info-management/my-president",
    "/info-management/back-to-basics",
  ];
  const getCurrentPath = () => {
    const path = getPathName[pathname];
    return path;
  };

  useEffect(() => {
    const isAuth = localStorage.getItem("ACCESS_TOKEN");
    if (!isAuth) {
      // If not authenticated, navigate to login page
      navigate("/login");
    }
  }, [navigate]);
  const backNavigationHandler = () => {
    navigate("/info-management");
  };

  console.log({pathname})
  return (
    <div className={`${isLoading ? "loading" : ""}`}>
      <div className="main_container">
        {/* <div className="main_container" style={{height:"100vh"}}> */}
        <div
          className={`sidebar-wrapper ${isOpen === true ? "close" : ""} ${
            isOpen === false ? "open" : ""
          } ${
            isSmallScreen === true ? "mobileViewSideBar" : "desktopViewSideBar"
          }`}
          id={`${
            isSmallScreen === true && isOpen === null
              ? "hideMobileViewSideBar"
              : "blockMobileViewSideBar"
          }`}
        >
          <Navigation />
        </div>

        <div>
          <div
            className={`navbar-wrapper ${
              isOpen === true ? "nav-grow" : "nav-shrink"
            }  ${isSmallScreen === true ? "nav-mobile-view" : ""}`}
          >
            <Nav />
          </div>
          <div
            className={`content-wrapper ${
              isOpen === true ? "grow-content" : ""
            } ${isOpen === false ? "shrink-content" : ""}`}
          >
            <div className="content-main-wrapper">
              <div className={pathname==="/content-management/user"||pathname==="/reviews"||pathname==="/verification"?"nav-main":""}>
                <div className="current_path">
                  <span className="pathname">{getCurrentPath()}</span>
                  {INCLUDE_BACK_BUTTON?.some((path) => path === pathname) ? (
                    skeletonLoading ? (
                      <Skeleton.Card
                        width={"3rem"}
                        height={"1rem"}
                        value={1}
                        borderRadius="4px"
                      />
                    ) : (
                      <Button onClick={backNavigationHandler}>Back</Button>
                    )
                  ) : null}
                </div>
                <div className="homepage-container">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && (
        <div className="loading-container">
          <Loader />
        </div>
      )}

      <Notification
        value={errorDetails?.errorName}
        display={errorDetails?.errorDisplay}
        onClose={() => {
          dispatch(errorClose(false));
        }}
      />
    </div>
  );
};

export default Index;
