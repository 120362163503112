
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  skeletonLoading:true
};
const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    isDataLoading: (state, action) => {
      state.isLoading = action.payload;
      state.skeletonLoading = false;

    },
    isSkeletonLoading: (state, action) => {
      state.skeletonLoading = action.payload;
    },
  },
});


export const { isDataLoading, isSkeletonLoading } = dataSlice.actions;

export default dataSlice.reducer; 

