import React, { useEffect, useState } from "react"
import { isSkeletonLoading } from "../redux/dataSlice";
import { mutationAPI } from "../api/services";
import { ADMIN_REVIEW_GET } from "../api/constants";
import { useDispatch } from "react-redux";
import { CustomizedTables, Pagination, Skeleton } from "../components";
import { formatToReadableDate, helpers } from "../utils/helper";
import { DeleteOutline, VisibilityIcon } from "../utils/constants";
const ReviewManagement = () => {
  const [data, setData] = useState([])
  const dispatch = useDispatch()

  let [handleOpenModal, setOpenHandleModal] = useState(false);
  let [page, setPage] = useState(0);
  let [keyData, SetKeyData] = useState({ title: "", years: "" })
  const [isLoaded, setIsLoaded] = useState(false); // Track preloading
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const handleOnClick = () => {
    setOpenHandleModal(true);
  }
  //  if (!data || !isLoaded) {
  //   return <Skeleton.Table />; // Show spinner while preloading
  // }
  const preloadAllImages = (data) => {
    data.forEach((record) => {
      record.media.forEach((mediaItem) => {
        const img = new Image();
        img.src = mediaItem.s3Url;
      });
    });
    setIsLoaded(true); // Mark as loaded after preloading
  };

  useEffect(() => {
    fetchData();

  }, [rowsPerPage , page]);

  const fetchData = async () => {
    (async () => {
      try {
        dispatch(isSkeletonLoading(true));


        const response = await mutationAPI(
          `${ADMIN_REVIEW_GET}?skipVal=${page}&limitVal=${rowsPerPage}`,
          "GET"
        );

        if (response?.status) {
          preloadAllImages(response?.data?.data); // Preload images


          setData(response?.data?.data?.map((item, i) => ({
            _id: item?._id,
            name: item?.userName,
            date: formatToReadableDate(item?.createdAt),
            reviewd: item?.businessUserName,
            media: Array.isArray(item?.media)
              ? item?.media?.map((m, index) => (
                m.fileType === "video" ? (
                  <video
                    key={index}
                    style={{
                      objectFit: "cover",
                      width: "300px",
                      height: "200px",
                      objectFit: "contain",
                    }}
                    controls
                    src={m.s3Url}
                  >
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img
                    key={index}
                    style={{
                      objectFit: "cover",
                      width: "300px",
                      height: "200px",
                      objectFit: "contain",
                      // background:"grey  "
                    }}
                    src={m.s3Url}
                    alt={m.fileName || "Media"}
                  />
                )
              ))
              : null

            , actions: [
              {
                key: "VIEW", icon:
                  <VisibilityIcon />,text:"VIEW"
              },

              { key: "DELETE", icon: <DeleteOutline />,text:"DELETE" }

            ]

          })) || []);
        }
      } catch (error) {
        // Handle any errors (e.g., log or display an error message)
      } finally {
        dispatch(isSkeletonLoading(false));
      }
    })();
  }
  console.log({ data })
  return (
    <div>

      <CustomizedTables
        componentData={data || []}
        componentHeaders={helpers.reviewsHeaders}
        page={1}
        // setPage={setPage}
        rowsPerPage={2}
        setData={setData}
        fetchList={fetchData}
        height= {"calc(100vh - 235px)"}

      />
                  <Pagination rowsPerPage={rowsPerPage} page={page} setRowsPerPage={setRowsPerPage} setPage={setPage}  />

    </div>
  )
}

export default ReviewManagement