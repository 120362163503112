import React, { useState, useEffect } from 'react';

const useMedia = (value) => {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < value);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < value);
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, [value]); 

 
    return {
        isSmallScreen
    };
};

export default useMedia;
