import { useNavigate } from "react-router-dom";
const Index = () => {
  const token = localStorage.getItem("ACCESS_TOKEN");
  const navigate = useNavigate();
  const navigateHandler = () => {
    token ? navigate("/dashboard") : navigate("/login");
  };
  return (
    <div className="not-found-page">
      <section class="wrapper">
        <div class="container">
          <div id="scene" class="scene" data-hover-only="false">
            <div class="circle" data-depth="1.2"></div>

            <div class="one">
              <div class="content">
                <span class="piece"></span>
                <span class="piece"></span>
                <span class="piece"></span>
              </div>
            </div>

            <div class="two">
              <div class="content">
                <span class="piece"></span>
                <span class="piece"></span>
                <span class="piece"></span>
              </div>
            </div>

            <p class="p404">404</p>
          </div>

          <div class="text">
            <article>
              <p>Oh! Looks like you got lost. </p>
              <button onClick={navigateHandler}>
                {token ? "HomePage" : "Login"}
              </button>
            </article>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Index;
