import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";


import { Button, CustomInputs, Input, Notification } from "../components";
import Logo from "../assets/reffyy_full_navy.png";
import { mutationAPI } from "../api/services";
import { ADMIN_LOGIN } from "../api/constants";
import { emailSchema } from "../utils/schema";
import { useValidationErrors } from "../utils/useErrors";
import { errorAvailable, errorClose } from "../redux/errorSlice";
import { title } from "../utils/constants";

const Login = () => {

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [animationStart, setAnimationStart] = useState(true)
  const [errors, setErrors, handleValidationErrors] = useValidationErrors();
  const dispatch = useDispatch();
  const errorDetails = useSelector((state) => state?.error);
  const location = useLocation();
  const isAuthenticated = localStorage.getItem("ACCESS_TOKEN");
  const [loaderAnimation, setLoaderAnimation] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  
  useEffect(() => {
    if (
      location?.pathname?.includes("login") &&
      isAuthenticated &&
      !location.state
    ) {
      navigate("/dashboard");
    }

    const timer = setTimeout(() => {
      setAnimationStart(false);
    }, 3000);

    const loaderTimer = setTimeout(() => {
      setLoaderAnimation(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
      clearTimeout(loaderTimer);
    };
  }, []);
console.log({formData})
  const handleLogin = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (formData?.email && formData?.password) {
      try {
        await emailSchema
          .validate(
            { email: formData.email.trim(), password: formData.password.trim() },
            { abortEarly: false }
          );
        setIsLoading(true)
        const response = await mutationAPI(ADMIN_LOGIN, "POST", { email: formData.email.trim(), password: formData.password.trim() });
        if (response.status === 400) {
          dispatch(errorAvailable(response?.err?.response?.data?.message));
        } else if (response.status === 500) {
          dispatch(errorAvailable(title?.wrong));
        } else {
          const emailAddress = response?.data?.data?.email;
          const accessToken = response?.data?.data?.token;

          localStorage.setItem("ACCESS_TOKEN", accessToken);
          localStorage.setItem("email", emailAddress);
          if (accessToken) {
            navigate("/dashboard");
          }
        }
      } catch (error) {
        console.log({ error }, "upali")
        handleValidationErrors(error);
      } finally {
        setIsLoading(false)
      }
    }

    else {
      setErrors({
        email: formData?.email ? "" : "Kindly enter email.",
        password: formData?.password ? "" : "Kindly enter password."

      })
    }
  };
  return (
    <React.Fragment>
      <div className="lui-auth-wrapper">
        <div className={`lui-auth-container ${animationStart ? "animated-form" : ""}`}>
          <div className="lui-auth-banner">
            {/* <Brand animationStart={loaderAnimation}/> */}
            <img src={Logo} alt="logo" />
          </div>
          <div className="lui-auth-heading">
            <h5>Welcome!</h5>
            <p>Please sign-in to your account.</p>
          </div>
          <div className="lui-auth-form">
            <form autoComplete="off">
              <div>
                {/* <CustomInputs
                  formData={formData}
                  setFormData={setFormData}
                  errors={errors}
                  setErrors={setErrors}
                /> */}
                 <Input
                type="email"
                label="Email"
                name="Email"
                value={formData?.email}
                onChange={(e) => setFormData({ ...formData, email: e?.target?.value })}
                />
                <Input type="password"
                 label="Password"
                 name="Password"
                 value={formData?.password}
                 onChange={(e) => setFormData({...formData,password:e?.target?.value})}

                />

                
              </div>
              <Button
                variant="primary"
                onClick={handleLogin}
                className="login-btn"
                loading={isLoading}
              >
                LOGIN
              </Button>
            </form>
          </div>
          <div className="lui-auth-form-actions">
            <p>
              <Link to={"/forgot-password"}>Forgot Password?</Link>
            </p>
          </div>
        </div>
      </div>
      <Notification
        value={errorDetails?.errorName}
        display={errorDetails?.errorDisplay}
        onClose={() => {
          dispatch(errorClose(false));
        }}
      />
    </React.Fragment>
  );
};


export default Login;
