import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Input from "../input";
import { mutationAPI } from "../../api/services";
import { ADMIN_CONTENT_DELETE_INDUSTRY, ADMIN_CONTENT_GET_INDUSTRY } from "../../api/constants";
import CrossIcon from "@mui/icons-material/Clear";
import { useDispatch } from "react-redux";
import { isDataLoading } from "../../redux/dataSlice";
import Button from "../button";



export default function DeleteModal(props) {
  const { isOpen, close, isDisable,data,setData,DeleteUrl,GetUrl } = props;
  const [deleted, setDeleted]=React.useState(false)
  const dispatch = useDispatch();

  const submitHandler=async()=>{
    setDeleted(true);
    setTimeout(()=>{
      setDeleted(false)
    },3000)
    try {
  dispatch(isDataLoading(true))
    const response = await mutationAPI(DeleteUrl, "DELETE", {_id:data?._id});
   const res = await mutationAPI(GetUrl, "GET",{});
   setData(res?.data?.data)
   close()   
  } catch (error) {
      console.log({error})
  }finally{
    dispatch(isDataLoading(false))
    close()  
  }
 }
  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        onClose={close}
        fullWidth
        maxWidth="sm"
        disableBackdropClick={true}
      >
        <DialogTitle className="dialog_title">Are your sure want to delete?
        <CrossIcon             onClick={() => close()}
        />
        </DialogTitle>
        <DialogContent>

        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => close()}
            sx={{
              fontWeight:600,

              textTransform:"capitalize",
              background: "Black",
              color: "#fff",
              "&:hover": {
                background: "Black",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={submitHandler}
            disabled={isDisable}
            loading={deleted}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
