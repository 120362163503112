import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {  dateConvertor, getAddress } from "../../utils/helper";
import EditIcon from "@mui/icons-material/Edit";
import {
  Cards,
  FloatPopUp,
  Modal,
  PopUp,
  CustomInputs,
  ToolTip,
} from "../index";
import { Box, TablePagination } from "@mui/material";
import { ADMIN_CONTENT_DELETE_EXPERIENCE, ADMIN_CONTENT_DELETE_INDUSTRY, ADMIN_CONTENT_GET_EXPERIENCE, ADMIN_CONTENT_GET_INDUSTRY, DISABLE_USER ,ADMIN_CONTENT_GET_USERTYPE , ADMIN_CONTENT_DELETE_USERTYPE} from "../../api/constants";
import { Skeleton, NoData } from "../index";
import { useDispatch } from "react-redux";
import React, { useRef, useState } from "react";
import avatar from "../../assets/avatar.png";


import { useValidationErrors } from "../../utils/useErrors";
import { useLocation } from "react-router-dom";
import ModalInterest from "../modal/IndustryModal";
import ModalIndustry from "../modal/IndustryModal";
import ModalUser from "../modal/UserModal";
import { DeleteOutline } from "@mui/icons-material";
import DeleteModal from "../modal/deleteModal";
import ModalExperience from "../modal/ExperienceModal";
import UserTypeModal from '../../components/modal/userTypeModal';


export default function CustomizedTables({
  componentData,
  componentHeaders,
  setPage,
  page,
  setRowsPerPage,
  rowsPerPage,
  setIsDetailsEditSuccessfully,
  setInitialLoad,
  setUserData,
  setData
}) {
  console.log({ componentData }, "=====>");
  const location = useLocation()
  const [rowData, setRowData] = React.useState({});
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenDelete, setIsOpenDelete] = React.useState(false);


  const [isOpenUser, setIsOpenUser] = React.useState(false);

  const [isOpenExperience, setIsOpenExperience] = React.useState(false);

  const [urlDelete, setDeleteUrl] = React.useState("");
  const [getUrl, setGetUrl] = React.useState("");


  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const [disableUser, setDisableUser] = useState("");
  const [viewCard, setViewCard] = React.useState(null);
  const [cardData, setCardData] = useState({});
  const { pathname } = useLocation();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setInitialLoad?.(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const editHandler = (value) => {
    console.log({pathname})
    if (pathname?.includes("/industry")) {
      setIsOpen(true);
      setRowData(value)

    }
    if (pathname?.includes("/user")) {
      setIsOpenUser(true);
      setRowData(value)

    }
    if (pathname?.includes("/experience")) {
      setIsOpenExperience(true);
      setRowData(value)

    }

  };
  const deleteHandler = (value, path) => {
    console.log("check",path)

    const url = path.split("/")
    switch (url[2]) {
      case "industry": 
        setIsOpenDelete(true);
        setRowData(value)
        setDeleteUrl(ADMIN_CONTENT_DELETE_INDUSTRY)
        setGetUrl(ADMIN_CONTENT_GET_INDUSTRY)
        break;
      case "experience":
        setIsOpenDelete(true);
        setRowData(value)
        setDeleteUrl(ADMIN_CONTENT_DELETE_EXPERIENCE)
        setGetUrl(ADMIN_CONTENT_GET_EXPERIENCE)
        break;
      case "user" :
        setIsOpenDelete(true);
        setRowData(value) 
        setDeleteUrl(ADMIN_CONTENT_DELETE_USERTYPE);
        setGetUrl(ADMIN_CONTENT_GET_USERTYPE)
        break; 
      default:
        break;
    }



  };



  if (!componentData?.length) return <Skeleton.Table />;
  if (!componentData?.length) return <NoData />;

  return (
    <React.Fragment>
      <TableContainer
        component={Paper}
        className="table_container"
        ref={tableRef}
      >
        <Table sx={{ width: "100%" }} aria-label="customized table">

          <TableHead>


            <TableRow className="table_header">
              {componentHeaders?.map((item) => {
                return <TableCell >{item}</TableCell>

              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {componentData?.map((row, i) => (
              <TableRow key={i + 1}>
                <TableCell >
                  <ToolTip
                    content={row?.heading || row?.title}
                    isCompressed={true}
                    isMax={25}
                    isToggle={true}
                  />
                </TableCell>
                <TableCell >
                  <ToolTip
                    content={row?.description||row?.count}
                    isCompressed={true}
                    isMax={60}
                    isToggle={true}
                  />
                </TableCell>

                <TableCell>
                  <Box className="icon_wrapper">
                    <ToolTip
                      element={
                        <Box>
                          <EditIcon
                            onClick={() => {
                              editHandler(row);
                            }}
                          />
                        </Box>
                      }
                      isToggle={true}
                      content="edit"
                    />
                    <ToolTip
                      element={
                        <Box>
                          <DeleteOutline
                            onClick={() => {
                              deleteHandler(row, location?.pathname);
                            }}
                          />
                        </Box>
                      }
                      isToggle={true}
                      content="Delete"
                    />
                  </Box>
                </TableCell>
                <FloatPopUp
                  children={
                    <div className="table_card">
                      <Cards
                        name={cardData?.name}
                        address={cardData?.address}
                        politicalParty={cardData?.politicalParty}
                        joiningDate={cardData?.joiningDate}
                        ethnicity={cardData?.ethnicity}
                        ethnicityType={cardData?.ethnicityType}
                        media={cardData?.media ?? avatar}
                        email={cardData?.email}
                      />
                    </div>
                  }
                  anchor={viewCard}
                  placement="left"
                  onClose={() => {
                    setViewCard(null);
                  }}
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        colSpan={3}
        count={10}
        rowsPerPage={rowsPerPage}
        page={page}
        slotProps={{
          select: {
            inputProps: {
              "aria-label": "rows per page",
            },
            native: true,
          },
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}

      {isOpen && (
        <ModalIndustry
          isEdit={true}
          isOpen={isOpen}
          close={() => {
            setIsOpen(false);
          }}
          data={rowData}
          setData={setData}
        />
      )}


      {isOpenUser && (
        <UserTypeModal
          isOpen={isOpenUser}
          isEdit={true}
          data = {rowData}
          close={() => {
            setIsOpenUser(false);
          }} 
          setData = {setData}
          />


      )}


      {isOpenExperience && (
        <ModalExperience
          data={rowData}

          isEdit={true}
          isOpen={isOpenExperience}
          close={() => {
            setIsOpenExperience(false)
          }}
          setData={setData}

        />
      )}
      {
        isOpenDelete && (
          <DeleteModal
            isOpen={isOpenDelete}
            close={() => {
              setIsOpenDelete(false);
            }}
            isDisable={false}
            data={rowData}
            setData={setData}
            DeleteUrl={urlDelete}
            GetUrl={getUrl}

          />

        )
      }

    </React.Fragment>
  );
}
