import React from "react"
import { CustomizedTables, Pagination } from "../components";
import { useEffect, useState } from "react";
import { mutationAPI } from "../api/services";

import { useDispatch } from "react-redux";
import { isDataLoading } from "../redux/dataSlice";
import { EditIcon, VisibilityIcon } from "../utils/constants";
import { helpers } from "../utils/helper";

const ExpertVerification = () => {
  const [data, setData] = useState([{ "id": "345678909865" }]);
  const [count, setCount] = useState([]);

  const [formData, setFormData] = useState({
    search: "",
  })

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const dispatch = useDispatch();

  const fetchUserList = async () => {
    try {
      // if(rowsPerPage === 10 || page === 0)  dispatch(isSkeletonLoading(true));
      if (rowsPerPage !== 10 || page !== 0) dispatch(isDataLoading(true));
      const response = await mutationAPI(`${"jjkdsf"}?skipVal=${page}&limitVal=${rowsPerPage}`, "GET");
      if (response?.status) {
        setData(data?.map((item, i) => ({
          _id: item?._id,
          userName: "upali", date: "8/7/24",
          email: "upali@gmail.com",

          actions: [
            { key: "VIEW", icon: <VisibilityIcon /> ,text:"VIEW"},
            { key: "EDIT", icon: <EditIcon />,text:"EDIT" },

          ]


        })) || []);
      }
    } catch (error) {

    } finally {
      dispatch(isDataLoading(false))
    }
  }

  useEffect(() => {

    fetchUserList()
  }, [rowsPerPage, page]);





  return (
    <div className="verify-management__comp">


      <CustomizedTables
        componentData={data || []}
        componentHeaders={helpers.ExpertHeaders}
        page={1}
        setPage={setPage}
        rowsPerPage={2}
        setData={setData}
        count={data?.length || count}
        fetchList={fetchUserList}
        height={"calc(100vh - 235px)"}

      />
      <Pagination rowsPerPage={rowsPerPage} page={page} setRowsPerPage={setRowsPerPage} setPage={setPage} />

    </div>
  );
}

export default ExpertVerification