import { useState } from 'react';

export const useValidationErrors = () => {
  const [errors, setErrors] = useState({});

  const handleValidationErrors = (error) => {
    if (error && error.name === "ValidationError") {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors); 
    } else {
      console.error(error);
    }
  };

  return [errors, setErrors, handleValidationErrors];
};
