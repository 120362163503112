import { Height } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import React, { forwardRef } from 'react';
const Button = forwardRef(
  ({ onClick, children, className = '', variant, disabled, loading=false ,style}, ref) => {
    return (
      <button
        className={`lui-btn ${variant}-btn ${className} ${loading?"loading":""}`}
        id={disabled ? "disabled-button":""}
        onClick={onClick}
        disabled={disabled}
        ref={ref}
        style={style}
      >
        {children}
      </button>
    );
  }
);

export default Button;
