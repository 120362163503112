import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HomeIcon from "@mui/icons-material/Home";
import Button from "../button";
import { useNavigate } from "react-router-dom";

function Cards(props) {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded(!expanded);
  const navigate = useNavigate();
  const renderDetail = (label, value) => (
    <div className="flex-wrapper">
      <h4>{label}</h4>
      <span>{value}</span>
    </div>
  );

  const renderIcon = (icon) => (
    <div className="flex-wrapper">
      <HomeIcon />
      <span>{icon && icon?.trim() ? icon : "N/A"}</span>
    </div>
  );
  const navigateHandler = () => {
    navigate(`/info-management/${props.pathName}/${props._id}`, {
      state: props,
    });
  };
  return (
    <div className="card_wrapper">
      <div className={`${props.media ? "img-flex-wrapper" : ""}`}>
        {props.heading && <h4>{props.heading}</h4>}
        {props.icon && props.icon}

        {props.name && <h2>{props.name}</h2>}
        {props.title && <h2 className="main_title">{props.title}</h2>}

        {props.media && (
          <div className="img_container">
            <img
              src={props.media}
              alt="media"
              // srcSet={`${props?.media}?size=small 300w, ${props?.media}?size=medium 768w, ${media}?size=large 1280w`}
              // sizes="(max-width: 600px) 300px, (max-width: 1200px) 768px, 1280px"
            />
          </div>
        )}
      </div>
      {props.count && <h3>{props.count}</h3>}
      {props.description && <container dangerouslySetInnerHTML={{ __html: props.description }} className="editor_content"/>}
      {props.link && (
        <div className="anchor">
          <a href={props.link} target="_blank" rel="noopener noreferrer">
            {props.link}
          </a>
        </div>
      )}

      {props.politicalParty &&
        renderDetail("Political Party", props.politicalParty)}

      {props.joiningDate && renderDetail("Joining Date", props.joiningDate)}
      {props.position && renderDetail("Position", props.position)}
      {props.ethnicity && renderDetail("Ethnicity", props.ethnicity)}
      {props.ethnicityType &&
        renderDetail("Ethnicity Type", props.ethnicityType)}
      {props.email && renderDetail("Email", props.email)}
      {props.address && renderIcon(props.address)}
      {props.experience && <p dangerouslySetInnerHTML={{ __html: props.experience }} />}
      {props.matchUpMedia && (
        <div className="media-matchup">
          <img src={props?.matchUpMedia} alt="media" />
        </div>
      )}
      {props.stance && renderDetail("Stance on", props.stance)}
      {props?.hoverId && (
        <Button onClick={navigateHandler} className="btn-card">
          Read More
        </Button>
      )}
    </div>
  );
}

export default Cards;
