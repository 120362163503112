import { createSlice } from "@reduxjs/toolkit";

const navigationSlice = createSlice({
  name: "navigation",
  initialState: {
    isOpen: null,
  },
  reducers: {
    openNavigation: (state, action) => {
      state.isOpen = action.payload;
    },
    closeNavigation: (state, action) => {
      state.isOpen = action.payload;
    },
    defaultNavigation:(state, action)=>{
      state.isOpen=action.payload
    }
  },
});

export const { openNavigation, closeNavigation, defaultNavigation } = navigationSlice.actions;
export default navigationSlice.reducer;
