import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import { ADMIN_CONTENT_EDIT_EXPERIENCE, ADMIN_CHANGE_STATUS, ADMIN_CONTENT_EDIT_GOAL, ADMIN_CONTENT_EDIT_INTEREST, ADMIN_CONTENT_EDIT_INDUSTRY, ADMIN_CONTENT_EDIT_USERTYPE, ADMIN_CONTENT_GET_INDUSTRY, ADMIN_CONTENT_GET_USERTYPE, ADMIN_CONTENT_GET_EXPERIENCE, ADMIN_CONTENT_GET_GOAL, ADMIN_CONTENT_GET_INTEREST, ADMIN_USER_LISTS, ADMIN_CONTENT_DELETE_USERTYPE, ADMIN_CONTENT_DELETE_EXPERIENCE, ADMIN_CONTENT_DELETE_GOAL, ADMIN_CONTENT_DELETE_INDUSTRY, ADMIN_CONTENT_DELETE_INTEREST, ADMIN_USER_DELETE_LIST, ADMIN_REVIEW_DELETE } from "../../api/constants";
import { Skeleton, NoData, ToolTip, PopUp, Pagination, FloatPopUp, Cards } from "../index";
import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import DeleteModal from "../modal/deleteModal";
import CustomModal from "../modal/CustomModal";
import { isDataLoading } from "../../redux/dataSlice";
import { mutationAPI } from "../../api/services";
import { useLocation } from "react-router-dom";
import { pathToConfigMap } from "../../utils/helper";
import ModalView from "../modal/viewModal";


export default function CustomizedTables({
  componentData,
  componentHeaders,
  searchVal,
  count,
  fetchList,
  setApiCall,
  height,
  
}) {
  const location = useLocation();
  const { pathname } = location;
  const [open, setOpen] = useState(false);
  const { skeletonLoading } = useSelector((state) => state?.data);
  const [rowData, setRowData] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenDelete, setIsOpenDelete] = React.useState(false);
  const [viewOpen, setViewOpen] = React.useState(false);
  const [viewCard, setViewCard] = React.useState(null);




  const dispatch = useDispatch();
  const [userStatus, setUserStatus] = useState("Active")
  const [review, setReview] = useState(false)
  const [verify, setVerify] = useState(false)


  // Find the correct config based on the pathname
  const configKey = pathToConfigMap[pathname];


  //props data for edit modal
  const moduleConfig = {
    userTypeManagement: {
      setResponseData: setRowData,
      dataResponse: rowData,
      isEdit: true,
      Close: setIsOpen,

      Open: isOpen,
      ApiData: {
        endPoint: ADMIN_CONTENT_EDIT_USERTYPE,
        method: "PATCH",
        getApi: fetchList

      },
      defaultResponse: {
        heading: "",
        description: "",
      },
      title: "UserType"
    },
    experienceManagement: {
      setResponseData: setRowData,
      dataResponse: rowData,
      isEdit: true,
      Close: setIsOpen,

      Open: isOpen,
      ApiData: {
        endPoint: ADMIN_CONTENT_EDIT_EXPERIENCE,
        method: "PATCH",
        getApi: ADMIN_CONTENT_GET_EXPERIENCE

      },
      defaultResponse: {
        title: "",
        years: "",
      },
      title: " Experience"
    },
    goalManagement: {
      setResponseData: setRowData,
      dataResponse: rowData,
      isEdit: true,
      Close: setIsOpen,

      Open: isOpen,
      ApiData: {
        endPoint: ADMIN_CONTENT_EDIT_GOAL,
        method: "PATCH",
        getApi: fetchList
      },
      defaultResponse: {
        title: "",
      },
      title: " Goal"

    },
    industryManagement: {
      setResponseData: setRowData,
      dataResponse: rowData,
      isEdit: true,
      Close: setIsOpen,

      Open: isOpen,
      ApiData: {
        endPoint: ADMIN_CONTENT_EDIT_INDUSTRY,
        method: "PATCH",
        getApi: fetchList
      },
      defaultResponse: {
        url: "",
        title: "",
      },
      title: " Industry"

    }, interestManagement: {
      setResponseData: setRowData,
      dataResponse: rowData,
      isEdit: true,
      Close: setIsOpen,

      Open: isOpen,
      ApiData: {
        endPoint: ADMIN_CONTENT_EDIT_INTEREST,
        method: "PATCH",
        getApi: fetchList
      },
      defaultResponse: {
        value: "",
      },
      title: " Interest"

    }, userManagement: {
      setResponseData: setRowData,
      dataResponse: rowData,
      isEdit: true,
      Close: setIsOpen,

      Open: isOpen,
      ApiData: {
        endPoint: ADMIN_CHANGE_STATUS,
        method: "PATCH",
        getApi: fetchList

      },
      defaultResponse: {
        username: "",
      },
      title: " User"

    }, expertManagement: {
      setResponseData: setRowData,
      dataResponse: rowData,
      isEdit: true,
      Close: setIsOpen,

      Open: isOpen,
      ApiData: {
        endPoint: "",
        method: "PATCH",
        getApi: fetchList

      },
      defaultResponse: {
        title: "",
      },
      title: " User"

    }
  }

  //props data for delete Modal
  const moduleDeleteConfig = {
    userTypeManagement: {
      isOpen: isOpenDelete,
      close: setIsOpenDelete,
      data: rowData,
      ApiData: {
        endPoint: ADMIN_CONTENT_DELETE_USERTYPE,
        method: "DELETE",
        getApi: fetchList

      },
      title: "UserType"
    },
    experienceManagement: {
      isOpen: isOpenDelete,
      close: setIsOpenDelete,
      data: rowData,

      ApiData: {
        endPoint: ADMIN_CONTENT_DELETE_EXPERIENCE,
        method: "DELETE",
        getApi: ADMIN_CONTENT_GET_EXPERIENCE

      },
      title: " Experience"
    },
    goalManagement: {
      isOpen: isOpenDelete,
      close: setIsOpenDelete,
      data: rowData,

      ApiData: {
        endPoint: ADMIN_CONTENT_DELETE_GOAL,
        method: "DELETE",
        getApi: fetchList
      },

      title: " Goal"

    },
    industryManagement: {
      isOpen: isOpenDelete,
      close: setIsOpenDelete,
      data: rowData,

      ApiData: {
        endPoint: ADMIN_CONTENT_DELETE_INDUSTRY,
        method: "DELETE",
        getApi: fetchList
      },
      title: " Industry"

    }, interestManagement: {
      isOpen: isOpenDelete,
      close: setIsOpenDelete,
      data: rowData,

      ApiData: {
        endPoint: ADMIN_CONTENT_DELETE_INTEREST,
        method: "DELETE",
        getApi: fetchList
      },
      title: " Interest"

    }, userManagement: {
      isOpen: isOpenDelete,
      close: setIsOpenDelete,
      data: rowData,

      ApiData: {
        endPoint: ADMIN_USER_DELETE_LIST,

        method: "DELETE",
        getApi: fetchList
      },
      title: " User"

    },
    reviews: {
      isOpen: isOpenDelete,
      close: setIsOpenDelete,
      data: rowData,

      ApiData: {
        endPoint: ADMIN_REVIEW_DELETE,
        method: "DELETE",
        getApi: fetchList
      },
      title: " Review"

    }
  }
  const selectedConfig = moduleConfig[configKey];
  const selectedDelete = moduleDeleteConfig[configKey]
  console.log({ selectedDelete }, moduleDeleteConfig[configKey], configKey)
  // handling icon here
  const handleIcon = (key, value, e) => {
    setRowData(value)
    console.log({ value }, configKey)
    console.log({ key })
    if (key === "EDIT") {
      if (pathname === "/verification") {
        setIsOpen(true);
        setVerify(true)

      } else {
        setIsOpen(true);

      }
    } else if (key === "DELETE") {
      setIsOpenDelete(true);
    }
    else if (key === "ACTIVE" || key === "INACTIVE") {
      setOpen(true);
    } else if (key === "VIEW") {
      if (configKey === "reviews") {
        setReview(true)
      }
      // if(pathname==="/verification"){
      //   setReview("verification")

      // }
      setRowData(value)
      setViewCard(e.target)
    }
  }

  console.log({ review })
  //conditions for loading state
  if (count?.length === 0 && !searchVal) return <Skeleton.Table />;
  if (!componentData?.length && searchVal) return <NoData />;
  if (skeletonLoading) return <Skeleton.Table />





  const modalProps = {
    isOpen: open,
    close: () => setOpen(!open),
    submitHandler: async () => {
      dispatch(isDataLoading(true));

      try {
        const payload = {
          _id: rowData?._id,
          isActive: rowData?.status === "Active" ? false : true
        }
        console.log(rowData?.status, { rowData })
        const response = await mutationAPI(`${ADMIN_CHANGE_STATUS}?_id=${payload?._id}`, 'PATCH', payload)
        // const res = await handleUserStatus(payload)
        if (response?.status == 200) {
          dispatch(isDataLoading(false));

          return setApiCall(true) && setOpen(false);

        }

      } catch (error) {

      } finally {
        setOpen(false);
      }
    },
    title: `Are you sure, you want to ${userStatus} the user?`,
    isDisable: false,
    children: null,
  };
  // Function to truncate text
  const truncateText = (text) => {
    return text?.length > 100 ? text?.split('').slice(0, 100).join('') + "..." : text;
  }

  console.log({ rowData })
  const excludeKeys = ["_id", "media"];

  return (
    <React.Fragment>
      <TableContainer
        component={Paper}
        className="table_container"
        style={{ height: `${height}` }}
      >
        <Table sx={{ width: "100%" }} aria-label="customized table" stickyHeader>

          <TableHead >


            <TableRow className="table_header">
              {componentHeaders?.map((item) => {
                return <TableCell >{item}</TableCell>

              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {componentData?.map((row, i) => (
              <TableRow key={i}>
                {Object.keys(row)
                  .filter((key) => !excludeKeys.includes(key)).map((key) => (
                    <TableCell key={key}>

                      {key === "actions" && Array.isArray(row[key]) ? ( // Check if it's an array and if the key is 'actions'
                        <Box className="icon_wrapper">

                          {row[key]?.map((action, index) => (
                            <>
                              <ToolTip
                                key={index}
                                element={
                                  <div
                                    onClick={(e) => {
                                      handleIcon(action?.key, row, e);
                                      setRowData(row);
                                    }}
                                  >
                                    {action?.icon}
                                  </div>
                                }
                                isToggle={true}
                                content={action?.text}
                              />
                            </>
                          ))}
                        </Box>
                      ) : (
                        <>
                          {key === "description" && row[key]?.length > 100 ? (
                            <ToolTip
                              isToggle={true}
                              content={row[key]}
                              element={
                                <div>
                                  {truncateText(row[key]) || "N/A"}
                                </div>
                              }
                            />
                          ) : (

                            key === "url" ?
                              <img src={row?.[key]} className="zoom-css" />

                              :
                              key === "status" ?
                                <div className={row[key] === "Active" ? "active-css" : "inactive-css"}>{row[key]}</div> :

                                <div>
                                  {row[key] || "N/A"}
                                </div>
                          )}
                        </>
                      )}
                    </TableCell>
                  ))}
              </TableRow>
            ))}
            <FloatPopUp

              children={
                <div className="table_card">
                  <Cards

                    data={rowData}
                    review={review}
                  />
                </div>
              }
              anchor={viewCard}
              placement="right"
              onClose={() => {
                setViewCard(null);
              }}
            />
          </TableBody>
        </Table>
      </TableContainer>


      {/* ACtive Inactive Modal */}
      <PopUp {...modalProps} />

      {/* Custom Delete Modal */}

      {
        isOpenDelete && (
          <DeleteModal

            {...selectedDelete}

          />
        )
      }
      {/*  Custom Edit or Create Modal */}

      {
        isOpen && selectedConfig && (
          <CustomModal
            {...selectedConfig}
            verify={verify}
          />
        )
      }
      {/*  View Modal */}
      {viewOpen &&
        <ModalView
          isOpen={viewOpen}
          close={setViewOpen}
          data={rowData}
        />}

      {/* Pagination */}


    </React.Fragment>
  );
}
