import * as React from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { titleSplice } from "../../utils/helper";
import { useSelector } from "react-redux";
import useMedia from "../../utils/useMedia";

const TooltipSx = ({ className, ...rest }) => {
  return (
    <Tooltip
      {...rest}
      arrow={true}
      classes={{ popper: className }}
      componentsProps={{
        popper: {
          sx: {
            [`& .${tooltipClasses.arrow}`]: {
              color: "black",
            },
            [`& .${tooltipClasses.tooltip}`]: {
              backgroundColor: "Black",
            },
          },
        },
      }}
    />
  );
};

export default function Index({
  content,
  isCompressed = false,
  isMax,
  isToggle = false,
  noToolTip = false,
  element = false,
}) {
  const sideNavigate = useSelector((state) => state?.navigation?.isOpen);
  const { isSmallScreen } = useMedia(1200);
  if (content === "N/A") {
    return <>{content}</>;
  } else if (noToolTip) return <>{content}</>;
  else if (element) {
    return <TooltipSx title={content}>{element}</TooltipSx>;
  } else {
    return (
      <>
        {isToggle && sideNavigate && !isSmallScreen ? (
          <>{content}</>
        ) : (
          <TooltipSx title={content}>
            {isCompressed ? titleSplice(content, isMax) : content}
          </TooltipSx>
        )}
      </>
    );
  }
}
