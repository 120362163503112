import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import layout from "../../assets/cutom-layout-bg.png";
import { Button, Skeleton } from "../../components";
import useMedia from "../../utils/useMedia";
import { useSelector } from "react-redux";

const Index = () => {
  const { pathname } = useLocation();
  const { isSmallScreen } = useMedia("1650");
  const { skeletonLoading } = useSelector((state) => state?.data);
  const navigate = useNavigate();
  const getSelectedValue = () => {
    if (pathname?.includes("candidate-matchup")) {
      return "Candidate Matchup";
    } else if (pathname?.includes("accountability-guide")) {
      return "Accountability Guide";
    }
  };
  const navigateHandler = () => {
    if (pathname?.includes("my-president")) {
      navigate("/info-management/my-president");
    } else {
      navigate("/info-management", { state: getSelectedValue() });
    }
  };

  return (
    <div className="nested-main">
      <div className="btn-nested">
        {skeletonLoading ? (
          <Skeleton.Card
            value={1}
            width="5rem"
            height="3rem"
            borderRadius={"8px"}
          />
        ) : (
          <Button onClick={navigateHandler}>BACK</Button>
        )}
      </div>
      <div className="nested-layout">
        {skeletonLoading ? (
          <div className="skeleton-layout">
            <Skeleton.Card value={1} width="100%" height="20rem" />
          </div>
        ) : (
          <div
            style={{
              backgroundImage: isSmallScreen && `url(${layout})`,
              backgroundColor: !isSmallScreen && "#804bdf",
            }}
            className="gradient"
          ></div>
        )}

        <Outlet />
      </div>
    </div>
  );
};

export default Index;
