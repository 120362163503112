import { Snackbar } from "@mui/material";
import React from "react";

const Notification = (props) => {
  const { value, display, onClose } = props;
  console.log(display)
  return (
    <Snackbar
      ContentProps={{
        sx: {
          background: "rgba(201, 255, 0, 0.85) ",
          fontWeight: 600,
          color: "black"
        }
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={display}
      message={value}
      autoHideDuration={5000}
      onClose={onClose}
    />


  );
};
export default Notification