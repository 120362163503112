
import React from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchIcon from '@mui/icons-material/Search';


const iconMap = {
  account: AccountCircleIcon,
  visibility: VisibilityIcon,
  search: SearchIcon
};

const Icon = ({ name, size = 24, color = 'inherit', ...props }) => {
  const SelectedIcon = iconMap[name];

  if (!SelectedIcon) return null;

  return <SelectedIcon style={{ fontSize: size, color }} {...props} />;
};

export default Icon;
