import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const Chart = ({ chartData, initialChartLoad }) => {
  const getMonth = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  const chartXAxis = () => {
    if (chartData?.length && "week" in chartData?.[0]) {
      return chartData?.map((value) => `week ${value?.week + 1}`);
    } else if (chartData?.length && "year" in chartData?.[0]) {
      return chartData?.map(
        ({ year, month }) => `${[getMonth[month]]}-${year}`
      );
    } else {
      return ["Value"];
    }
  };

  const chartValues = () => {
    return chartData?.map(({ count }) => count);
  };

  const options = {
    chart: {
      renderTo: "container",
      type: "column",
      options3d: {
        enabled: true,
        alpha: 15,
        beta: 15,
        depth: 50,
        viewDistance: 25,
      },
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: initialChartLoad
        ? ["week 1", "week 2", "week 3"]
        : chartXAxis(),
    },
    yAxis: {
      title: {
        enabled: false,
      },
    },
    tooltip: {
      headerFormat: "<b>{point.key}</b><br>",
      pointFormat: "Users: {point.y}",
    },
    title: {
      text: chartData?.length
        ? "Registered users"?.toUpperCase()
        : initialChartLoad
        ? ""
        : "",
      align: "left",
      style: {
        fontSize: "18px",
        fontWeight: "bold",
        fontFamily: '"Public Sans", sans-serif"',
        color: "rgb(38, 38, 38)",
        cursor: "pointer",
      },
      margin: 30,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        depth: 25,
      },
    },
    series: [
      {
        data: initialChartLoad ? [1, 2, 4] : chartValues(),
        colorByPoint: true,
      },
    ],
  };

  return (
    <>
     <HighchartsReact highcharts={Highcharts} options={options} />
    </>
  );
};

export default Chart;
