import React from "react";
import logo from "../../assets/no_data.svg";
const NoData = ({title="No user found..!"}) => {
  return (
    <div className="no-content">
      <img src={logo} alt="No Data" />
      <span>{title}</span>
    </div>
  );
};

export default NoData;
