import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HomeIcon from "@mui/icons-material/Home";
import Button from "../button";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box, IconButton } from "@mui/material";
import GIF from "../../assets/no_data_found.gif"
import IMG from "../../assets/avatar.png"
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
function Cards(props) {

  const { data ,review} = props

  console.log({review})
  const [currentImageIndex, setCurrentImageIndex] = useState(0);



  // Handle next image navigation
  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % data.media.length);
  };

  // Handle previous image navigation
  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + data.media.length) % data.media.length);
  };
  return (
    <Box className="box-css" padding={2}>

      {review  ?
       <Card sx={{ maxWidth: 300, position: "relative",boxShadow:"0px 4px 10px rgba(33, 43, 68, 0.7)" }}> 
      {/* Display current image */}

    {data.media[currentImageIndex] === undefined ?

      
      <img className="img-css"
      src={GIF}/>
     :      <div >{data.media[currentImageIndex]}</div>
 
} 
      {/* Card content */}
    <CardContent sx={{background:"rgb(33, 43, 68)",color:"white"}}>
        <Typography variant="h6" component="div">
          {data?.name || "--"}
        </Typography>
        <Typography variant="body2" color="white">
          {data?.businessUserName || ""}
        </Typography>
        <Typography variant="body2" color="white">
          {data?.date || ""}
        </Typography>
      </CardContent>
      {/* Navigation arrows */}
 { data.media[currentImageIndex] !== undefined && data.media?.length>0 &&
      <>
      <IconButton
        sx={{
          position: "absolute",
          top: "50%",
          left: 8,
          transform: "translateY(-50%)",
          backgroundColor: "rgba(255,255,255,0.7)",
        }}
        onClick={handlePrevImage}
      >
        <ArrowBackIosIcon sx={{          width:"20px",
}} />
      </IconButton>
      <IconButton
        sx={{

          position: "absolute",
          top: "50%",
          right: 8,
          transform: "translateY(-50%)",
          backgroundColor: "rgba(255,255,255,0.7)",
        }}
        onClick={handleNextImage}
      >
        <ArrowForwardIosIcon sx={{          width:"20px",
}}/>
      </IconButton></>} 
      </Card> 
: 
      <Card sx={{
        maxWidth: 300, position: "relative", boxShadow: "0px 4px 10px rgba(33, 43, 68, 0.7)", height: "300px", width: "250px", border: "5px solid rgb(226 255 116)", borderRadius: "20px", position: "relative",

        overflow: "hidden"
      }}>
        <Box className="semi-circle">

          <img src={IMG} alt="Circular Image" class="circular-image" />  </Box>

        <div
          className="div-card">
          <div>  <p className="text" >Upali Khanduri</p></div>
          <div className="approve-css">
            <Button
              style={{ background: "rgb(10, 243, 10)" }}
              className="submitted-button"
            >
              Approve
            </Button>
            <Button
              className="closed-button"

              style={{ background: " rgb(252, 10, 10)" }}

            >
              Decline          
              </Button></div>
        </div>
      </Card>}

    </Box>



  );
}

export default Cards;
