import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Input from "../input";
import { mutationAPI } from "../../api/services";
import { ADMIN_CONTENT_CREATE_INDUSTRY, ADMIN_CONTENT_EDIT_INDUSTRY, ADMIN_CONTENT_GET_INDUSTRY } from "../../api/constants";
import Edit from "@mui/icons-material/Edit";
import logoProfile from "../../assets/profile.png"
import CrossIcon from "@mui/icons-material/Clear";
import { useDispatch } from "react-redux";
import { isDataLoading } from "../../redux/dataSlice";


export default function ModalIndustry(props) {
  const { isOpen, isEdit, close, data, title, isDisable, setData } = props;

  const [myimage, setMyImage] = React.useState(null);
  const [selectedImage, setSelectedImage] = React.useState(data?.url);
  const [industryCount, setIndustryCount] = React.useState(data?.count || "");


  const [titleVal, setTitleVal] = React.useState(data?.title || "")
  const fileInputRef = React.useRef(null);
  const dispatch = useDispatch();

  const uploadImage = e => {
    setMyImage(URL.createObjectURL(e.target.files[0]));
  };
  const submitHandler = async () => {
    const payload = {
      title: titleVal,
      count: industryCount,
      url: selectedImage
    }
    if (data?._id) {
      payload._id = data._id;
    }
    if (isEdit) {
      try {

        dispatch(isDataLoading(true))
        const response = await mutationAPI(ADMIN_CONTENT_EDIT_INDUSTRY, "PATCH", payload);

        if (response?.status == 200) {
          const data = await mutationAPI(ADMIN_CONTENT_GET_INDUSTRY, "GET", {});
          setData(data?.data?.data)
        }
        close()
      } catch (error) {
        close()

      } finally {
        dispatch(isDataLoading(false))
      }
    } else {
      try {
        dispatch(isDataLoading(true))

        const response = await mutationAPI(ADMIN_CONTENT_CREATE_INDUSTRY, "POST", payload);
        console.log({ response })
        if (response?.status == 200) {
          const data = await mutationAPI(ADMIN_CONTENT_GET_INDUSTRY, "GET", {});
          setData(data?.data?.data)
        } close()

      } catch (error) {
        close()

      } finally {
        dispatch(isDataLoading(false))
      }
    }

  }
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {

      const reader = new FileReader();
      reader.onloadend = () => {
        // setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);

      const formData = new FormData();
      formData.append('files', file);
      try {
        const response = await mutationAPI(
          "/v1/auth/uploadImage",
          "POST",
          formData
        )
        setSelectedImage(response?.data?.data?.[0])
      }
      catch (err) {

      }
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  }; return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        onClose={close}
        fullWidth
        maxWidth="sm"
        disableBackdropClick={true}
      >
        <DialogTitle className="dialog_title">{isEdit ? "Edit Industry " : "Create Industry"}

          <CrossIcon onClick={() => close()}
          />
        </DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", flexDirection: "column", gap: "15px", paddingTop: "1rem" }}>
            <div style={{
              height: "4.948vw",
              width: "4.948vw",
              borderRadius: "3.49vw",
              border: "1px solid #dcdcdc",
              backgroundColor: "#fff",
              position: "relative"
            }}>
              {!selectedImage ?
                <div >
                  <img src={logoProfile} style={{
                    height: "4.948vw",
                    width: "4.948vw",
                    borderRadius: "3.49vw"
                  }} />
                </div>
                :
                <img
                  // key={index}
                  src={selectedImage}
                  alt={`profile`}
                  style={{
                    objectFit: 'cover', margin: '3px', width: "100%",
                    height: " 100%",
                    borderRadius: "50%",
                  }}
                />
              }
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                ref={fileInputRef}
                style={{ display: 'none' }}
              />
              <div onClick={handleButtonClick} style={{
                position: "absolute",
                height: "1.25vw",
                width: "1.25vw",
                backgroundColor: "#fff",
                border: "1px solid #dcdcdc",
                borderRadius: "2.083vw",
                display: "flex",
                justifyContent: "center",
                aligntems: "center",
                cursor: "pointer",
                bottom: "0",
                right: "0"
              }}>
                <Edit style={{ height: ".833vw", width: ".833vw", color: "#86868B" }} />
              </div>
            </div>
            <div>
              <p>Title</p>
              <Input
                type="text"
                label=""
                name="name"
                value={titleVal}
                onChange={(e) => setTitleVal(e?.target?.value)}
              /></div>
            <div>
              {/* <p>Count</p>

              <Input
              type="number"
              label=""
              name="name"
              value={industryCount}
              onChange={(e)=>setIndustryCount(e?.target?.value)}
            /></div> */}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => close()}
            sx={{
              fontWeight: 600,

              textTransform: "capitalize",

              background: "Black",
              color: "#fff",
              "&:hover": {
                background: "Black",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={submitHandler}
            disabled={isDisable}
            sx={{
              textTransform: "capitalize",
              fontWeight: 600,
              background: isDisable ? "grey" : "#c9ff00",
              cursor: isDisable ? "not-allowed" : "pointer",
              color: "black !important",
              "&:hover": {
                background: isDisable ? "grey" : "#c9ff00",
              },
            }}
          >
            {isEdit ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
